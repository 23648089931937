import React, { useCallback } from 'react'

import BatchImporterHeader from '@src/components/BatchImporter/BatchImporterHeader'
import BatchImporterImportingBody from '@src/components/BatchImporter/BatchImporterImporting/BatchImporterImportingBody'
import {
  BatchImporterEditMedia,
  TrackingDetails
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import { FullScreenLayout } from '@src/components/FullScreenLayout'
import useActivationStatus from '@src/hooks/Onboarding/useActivationStatus'

interface IProps {
  title: string
  onClose: () => void
  mediaList?: BatchImporterEditMedia[]
  tracking: TrackingDetails
}

const BatchImporterImporting: React.FC<IProps> = (props) => {
  const { title, onClose, mediaList, tracking } = props
  const { setUploadVideoComplete } = useActivationStatus()

  const handleComplete = useCallback(() => {
    setUploadVideoComplete()
    onClose?.()
  }, [onClose, setUploadVideoComplete])

  return (
    <FullScreenLayout position="fixed" bottom={0} right={0}>
      <BatchImporterHeader title={title} rightControls={<></>} />
      <BatchImporterImportingBody
        mediaList={mediaList}
        onCreateComplete={handleComplete}
        tracking={tracking}
      />
    </FullScreenLayout>
  )
}

export default BatchImporterImporting
