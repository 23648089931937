import { useCallback, useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { ImporterSourceFrom } from '@src/components/BatchImporter/BatchImporterMediaModel'
import useAsyncImporterTask from '@src/hooks/useAsyncImporterTask'
import { useVideoLimit } from '@src/hooks/useVideoLimit'
import {
  AsyncSourceImporterTask,
  AsyncSourceImporterTaskDetailStatus,
  AsyncSourceImporterTaskStatus,
  updateAsyncImporterTasks
} from '@src/redux/asyncSourceImporter'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const useAsyncImporterTaskManager = ({
  channelId,
  businessId,
  userId
}: {
  channelId: string
  businessId: string
  userId: string
}): void => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { maxVideoSize, maxVideoDuration } = useVideoLimit({
    businessId,
    channelId
  })
  const { youTubeImporter } = useAsyncImporterTask()
  const maxConcurrentPerGroup = 2
  const runningTasks = useRef<Record<string, Set<string>>>({})
  const taskQueues = useRef<Record<string, AsyncSourceImporterTask[]>>({})

  const [currentTasks, setCurrentTasks] = useState<AsyncSourceImporterTask[]>(
    []
  )
  const tasks = useAppSelector((state) => state.asyncSourceImporter.tasks)

  const getGroupKey = useCallback(
    (businessId: string, channelId: string, userId: string) => {
      return `${businessId}-${channelId}-${userId}`
    },
    []
  )

  const handleStartTask = useCallback(
    async (task: AsyncSourceImporterTask) => {
      if (task.media.sourceFrom === ImporterSourceFrom.YouTube) {
        await youTubeImporter(task, { maxVideoSize, maxVideoDuration })
      } else {
        throw new Error(t('Unknown source'))
      }
    },
    [t, youTubeImporter, maxVideoDuration, maxVideoSize]
  )

  const handleErrorTask = useCallback(
    async (task: AsyncSourceImporterTask, error: Error) => {
      await dispatch(
        updateAsyncImporterTasks({
          tasks: [
            {
              ...task,
              status: AsyncSourceImporterTaskStatus.Fail,
              detailStatus: AsyncSourceImporterTaskDetailStatus.CreateFailed,
              media: {
                ...task.media,
                error: {
                  message: error.message
                }
              }
            }
          ]
        })
      )
    },
    [dispatch]
  )

  const executeTask = useCallback(
    async (task: AsyncSourceImporterTask, onComplete: () => void) => {
      try {
        await handleStartTask(task)
      } catch (e) {
        await handleErrorTask(task, e)
      } finally {
        onComplete()
      }
    },
    [handleStartTask, handleErrorTask]
  )

  const processNextTask = useCallback(
    async (groupKey: string) => {
      const runningSet = runningTasks.current[groupKey] || new Set()
      const queue = taskQueues.current[groupKey] || []

      if (runningSet.size < maxConcurrentPerGroup && queue.length > 0) {
        const nextTask = queue.shift()
        if (nextTask) {
          runningSet.add(nextTask.id)
          runningTasks.current[groupKey] = runningSet
          taskQueues.current[groupKey] = queue

          await executeTask(nextTask, () => {
            runningSet.delete(nextTask.id)
            processNextTask(groupKey)
          })
        }
      }
    },
    [executeTask, maxConcurrentPerGroup]
  )

  const enqueueTask = useCallback(
    async (task: AsyncSourceImporterTask) => {
      const groupKey = getGroupKey(task.businessId, task.channelId, task.userId)

      if (!taskQueues.current[groupKey]) {
        taskQueues.current[groupKey] = []
      }
      if (!runningTasks.current[groupKey]) {
        runningTasks.current[groupKey] = new Set()
      }

      const queue = taskQueues.current[groupKey]
      const runningSet = runningTasks.current[groupKey]

      const isTaskAlreadyEnqueued =
        queue.some((t) => t.id === task.id) || runningSet.has(task.id)

      if (!isTaskAlreadyEnqueued) {
        queue.push(task)
        await processNextTask(groupKey)
      }
    },
    [getGroupKey, processNextTask]
  )

  useEffect(() => {
    currentTasks.forEach((task) => {
      if (task.status === AsyncSourceImporterTaskStatus.Pending) {
        enqueueTask(task).then()
      }
    })
  }, [currentTasks, enqueueTask])

  useEffect(() => {
    if (businessId && channelId && userId) {
      const cTasks = tasks.filter((task) => {
        return (
          task.businessId === businessId &&
          task.channelId === channelId &&
          task.userId === userId
        )
      })
      setCurrentTasks(cTasks)
    }
  }, [businessId, channelId, userId, tasks])
}

export default useAsyncImporterTaskManager
