import {
  BatchImporterEditMedia,
  ImporterSourceFrom
} from '@src/components/BatchImporter/BatchImporterMediaModel'
import { AsyncSourceImporterTask } from '@src/redux/asyncSourceImporter'
import { localStorageProvider } from '@src/utils/storageProvider'

const filterNewMediaList = (
  mediaList: BatchImporterEditMedia[],
  tasks: AsyncSourceImporterTask[]
): BatchImporterEditMedia[] => {
  return mediaList?.filter((media: BatchImporterEditMedia) => {
    const existingTasks = tasks.find((task) => {
      return task.id === media.id
    })

    // only support youtube in current version
    return (
      !existingTasks &&
      !!media.id &&
      media.sourceFrom === ImporterSourceFrom.YouTube
    )
  })
}

const filterExistingMediaList = (
  mediaList: BatchImporterEditMedia[],
  tasks: AsyncSourceImporterTask[]
): BatchImporterEditMedia[] => {
  return mediaList?.filter((media: BatchImporterEditMedia) => {
    const existingTasks = tasks.find((task) => {
      return task.id === media.id
    })

    // only support youtube in current version
    return (
      existingTasks &&
      !!media.id &&
      media.sourceFrom === ImporterSourceFrom.YouTube
    )
  })
}

const saveAsyncImporterTasks = (tasks: AsyncSourceImporterTask[]): void => {
  localStorageProvider.setItem(
    'async_importer_tasks',
    JSON.stringify(tasks || [])
  )
}

const getAsyncImporterTasks = (): AsyncSourceImporterTask[] => {
  return JSON.parse(
    localStorageProvider.getItem('async_importer_tasks') || '[]'
  )
}

export {
  filterNewMediaList,
  filterExistingMediaList,
  saveAsyncImporterTasks,
  getAsyncImporterTasks
}
