import {
  createSlice,
  Slice,
  SliceCaseReducers,
  createAsyncThunk
} from '@reduxjs/toolkit'

import api from '../common/utils/api'

const initialState = {
  billing: {
    billings: [],
    paging: {}
  },
  loading: false,
  error: null
}

const SLICE_NAME = 'billing'

export const fetchBilling = createAsyncThunk(
  `${SLICE_NAME}/fetchBilling`,
  async (params: { businessId: string }) => {
    const response = await api.get(`/bus/${params.businessId}/billings`)

    return response.data
  }
)

export const createBilling = createAsyncThunk(
  `${SLICE_NAME}/createBilling`,
  async (params: { businessId: string; billing: globalLib.BillingPayload }) => {
    const response = await api.post(
      `/bus/${params.businessId}/billings`,
      params.billing
    )

    return response.data
  }
)

export const updateBilling = createAsyncThunk(
  `${SLICE_NAME}/updateBilling`,
  async (params: {
    businessId: string
    billingId: string
    billing: globalLib.BillingPayload
  }) => {
    const response = await api.patch(
      `/bus/${params.businessId}/billings/${params.billingId}`,
      params.billing
    )

    return response.data
  }
)

const slice: Slice<
  typeof initialState,
  SliceCaseReducers<typeof initialState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBilling.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchBilling.fulfilled, (state, action) => {
        state.loading = false
        state.billing = action.payload
        state.error = null
      })
      .addCase(createBilling.pending, (state) => {
        state.loading = true
      })
      .addCase(createBilling.fulfilled, (state, action) => {
        const billing = action.payload
        state.billing.billings.push(billing)
        state.loading = false
      })
      .addCase(updateBilling.pending, (state) => {
        state.loading = true
      })
      .addCase(updateBilling.fulfilled, (state, action) => {
        const billing = action.payload
        state.billing.billings = state.billing.billings.map((item) => {
          if (item.id === billing.id) {
            return billing
          }

          return item
        })
        state.loading = false
      })
      .addCase(fetchBilling.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
  },
  reducers: {}
})

export default slice.reducer
