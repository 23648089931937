import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const CreateStore = loadable(() => import('../pages/CreateStore'))
const Store = loadable(() => import('../pages/Store'))

export const Routes = (
  <>
    <Route
      path="/business/:businessId/store/create"
      element={<CreateStore />}
    />
    <Route path="/business/:businessId/connect_store" element={<Store />} />
    <Route path="/business/:businessId/store/:storeId" element={<Store />} />
    <Route
      path="/business/:businessId/store/:storeId/:storeTab"
      element={<Store />}
    />
  </>
)
