import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../common/utils/api'

type IProps = {
  statements: Record<string, any>
  paging: Record<string, globalLib.Paging>
}

const initialState: IProps = {
  statements: {},
  paging: {}
}

const slice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {
    fetchStatementsSuccess(state, action) {
      const { businessId, statements, paging } = action.payload
      state.statements[businessId] = statements
      state.paging[businessId] = paging
    }
  }
})

export default slice.reducer

export const { fetchStatementsSuccess } = slice.actions

const fetchStatementsRequest = createAction('payment/fetchStatementsRequest')
const fetchStatementsFailure = createAction('payment/fetchStatementsFailure')

export function fetchStatements(businessId: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchStatementsRequest())
      const response = await api.get(page || `/bus/${businessId}/statements`)
      const { statements, paging } = response.data
      dispatch(fetchStatementsSuccess({ businessId, statements, paging }))
    } catch (error) {
      dispatch(fetchStatementsFailure())
    }
  }
}
