import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Apps = loadable(() => import('../pages/Apps'))

export const Routes = (
  <>
    <Route path="/business/:businessId/app/:oauthAppId" element={<Apps />} />
    <Route
      path="/business/:businessId/app/:oauthAppId/:tab"
      element={<Apps />}
    />
  </>
)
