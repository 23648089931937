import api, { API_URL, IMPORTER_PROXY_URL } from '@src/common/utils/api'
import { delay } from '@src/common/utils/tools'
import {
  YouTubeCrawlerMedaDataItem,
  YouTubeCrawlerUrlItem
} from '@src/components/SourceImporter/YouTube/YouTubeImporterModel'
import { InstagramCrawlerItem } from '@src/redux/igCrawlerImporter'
import { TiktokItem } from '@src/redux/tiktokImporter'
import { localStorageProvider } from '@src/utils/storageProvider'

export const APIFY_RESULTS_PER_PAGE = 50

const INTERVAL = 3000

export type ApifyInput = {
  parameters?: any
  actionId?: string
  datasetId?: string
  runId?: string
}

export type ApifyOutput = {
  items?: any[]
} & ApifyInput

const runActorAndGetResultsSync = async (
  input: ApifyInput,
  isUrlCrawler?: boolean
): Promise<ApifyOutput> => {
  let datasetId = input?.datasetId
  let runId = input?.runId
  if (!datasetId) {
    // Run the Actor and wait for it to finish
    let run: any
    if (!runId) {
      run = (
        await (
          await fetch(`${IMPORTER_PROXY_URL}/apify/start`, {
            // POST urls to the API
            method: 'POST',
            headers: {
              Authorization: `bearer ${api.getToken()}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ...input,
              fwHost: API_URL
            })
          })
        ).json()
      ).data

      runId = run.id

      if (isUrlCrawler) {
        saveApifyOutput({ ...input, runId }, false)
      }
    } else {
      run = (
        await (
          await fetch(`${IMPORTER_PROXY_URL}/apify/status?runId=${runId}`)
        ).json()
      ).data
    }

    // interval loop to check the status of the run
    while (run.status !== 'SUCCEEDED' && run.status !== 'FAILED') {
      run = (
        await (
          await fetch(`${IMPORTER_PROXY_URL}/apify/status?runId=${run.id}`)
        ).json()
      ).data
      await delay(INTERVAL)
    }

    datasetId = run.defaultDatasetId
    if (isUrlCrawler) {
      saveApifyOutput({ ...input, runId, datasetId }, false)
    }
  }

  const items = await (
    await fetch(`${IMPORTER_PROXY_URL}/apify/dataset?datasetId=${datasetId}`)
  ).json()

  if (isUrlCrawler) {
    saveApifyOutput({ ...input, runId, datasetId, items }, true)
  }

  return {
    ...input,
    items,
    datasetId,
    runId
  }
}

const UrlsCrawlerActionId = {
  Tiktok: 'clockworks~free-tiktok-scraper',
  Instagram: 'apify~instagram-scraper',
  YouTube: 'streamers~youtube-scraper', // no download video url
  YouTubeVideo: 'epctex~youtube-video-downloader' // include download video url
}

type UrlCrawlerData = {
  inputUrl: string
  actionId: string
  datasetId?: string
  runId?: string
  item?: any
  expiresIn: number
  crawCompleted: boolean
}

const urlsCrawlerDataExpiresIn = 6 * 24 * 60 * 60 * 1000

const saveTiktokApifyOutput = (
  output: ApifyOutput,
  crawCompleted?: boolean
) => {
  if (!output) {
    return
  }

  const actionId: string = output.actionId
  if (actionId !== UrlsCrawlerActionId.Tiktok) {
    return
  }

  const inputUrls: string[] = output.parameters.postURLs
  const record = getUrlCrawlerDataRecord()

  if (!crawCompleted) {
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      record[key] = {
        inputUrl,
        actionId,
        datasetId: output.datasetId,
        runId: output.runId,
        crawCompleted: false,
        expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
      }
    }
  } else {
    const items: TiktokItem[] =
      output.items?.filter((item) => {
        return !item.error && item?.videoMeta?.coverUrl
      }) || []
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      const item = items.find((item) => {
        return item.submittedVideoUrl === inputUrl
      })
      if (item) {
        record[key] = {
          inputUrl,
          actionId,
          datasetId: output.datasetId,
          runId: output.runId,
          item,
          crawCompleted: true,
          expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
        }
      } else {
        delete record[key]
      }
    }
  }

  localStorageProvider.setItem(
    `fw_apify_url_crawler_data_list`,
    JSON.stringify(Object.values(record))
  )
}

const saveInstagramApifyOutput = (
  output: ApifyOutput,
  crawCompleted?: boolean
) => {
  if (!output) {
    return
  }

  const actionId: string = output.actionId
  if (actionId !== UrlsCrawlerActionId.Instagram) {
    return
  }

  const inputUrls: string[] = output.parameters.directUrls
  const record = getUrlCrawlerDataRecord()

  if (!crawCompleted) {
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      record[key] = {
        inputUrl,
        actionId,
        datasetId: output.datasetId,
        runId: output.runId,
        crawCompleted: false,
        expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
      }
    }
  } else {
    const items: InstagramCrawlerItem[] =
      output.items?.filter((item) => {
        return !item.error && !!item?.id && !!item?.type
      }) || []
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      const item = items.find((item) => {
        return item.inputUrl === inputUrl
      })
      if (item) {
        record[key] = {
          inputUrl,
          actionId,
          datasetId: output.datasetId,
          runId: output.runId,
          item,
          crawCompleted: true,
          expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
        }
      } else {
        delete record[key]
      }
    }
  }

  localStorageProvider.setItem(
    `fw_apify_url_crawler_data_list`,
    JSON.stringify(Object.values(record))
  )
}

const saveYouTubeApifyOutput = (
  output: ApifyOutput,
  crawCompleted?: boolean
) => {
  if (!output) {
    return
  }

  const actionId: string = output.actionId
  if (actionId !== UrlsCrawlerActionId.YouTube) {
    return
  }

  const inputUrls: string[] = output.parameters.startUrls?.map((item) => {
    return item.url
  })
  const record = getUrlCrawlerDataRecord()

  if (!crawCompleted) {
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      record[key] = {
        inputUrl,
        actionId,
        datasetId: output.datasetId,
        runId: output.runId,
        crawCompleted: false,
        expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
      }
    }
  } else {
    const items: YouTubeCrawlerMedaDataItem[] =
      output.items?.filter((item) => {
        return !!item?.id
      }) || []
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      const item = items.find((item) => {
        return item.input === inputUrl
      })
      if (item) {
        record[key] = {
          inputUrl,
          actionId,
          datasetId: output.datasetId,
          runId: output.runId,
          item,
          crawCompleted: true,
          expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
        }
      } else {
        delete record[key]
      }
    }
  }

  localStorageProvider.setItem(
    `fw_apify_url_crawler_data_list`,
    JSON.stringify(Object.values(record))
  )
}

const saveYouTubeVideoApifyOutput = (
  output: ApifyOutput,
  crawCompleted?: boolean
) => {
  if (!output) {
    return
  }

  const actionId: string = output.actionId
  if (actionId !== UrlsCrawlerActionId.YouTubeVideo) {
    return
  }

  const inputUrls: string[] = output.parameters.startUrls
  const record = getUrlCrawlerDataRecord()

  if (!crawCompleted) {
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      record[key] = {
        inputUrl,
        actionId,
        datasetId: output.datasetId,
        runId: output.runId,
        crawCompleted: false,
        expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
      }
    }
  } else {
    const items: YouTubeCrawlerUrlItem[] =
      output.items?.filter((item) => {
        return !!item?.downloadUrl
      }) || []
    for (const inputUrl of inputUrls) {
      const key = `${inputUrl}_${actionId}`
      const item = items.find((item) => {
        return item.sourceUrl === inputUrl
      })
      if (item) {
        record[key] = {
          inputUrl,
          actionId,
          datasetId: output.datasetId,
          runId: output.runId,
          item,
          crawCompleted: true,
          expiresIn: new Date().getTime() + urlsCrawlerDataExpiresIn
        }
      } else {
        delete record[key]
      }
    }
  }

  localStorageProvider.setItem(
    `fw_apify_url_crawler_data_list`,
    JSON.stringify(Object.values(record))
  )
}

const saveApifyOutput = (
  output: ApifyOutput,
  crawCompleted?: boolean
): void => {
  if (!output) {
    return
  }

  const actionId: string = output.actionId
  if (!Object.values(UrlsCrawlerActionId).includes(actionId)) {
    return
  }

  if (actionId === UrlsCrawlerActionId.Tiktok) {
    saveTiktokApifyOutput(output, crawCompleted)
  } else if (actionId === UrlsCrawlerActionId.Instagram) {
    saveInstagramApifyOutput(output, crawCompleted)
  } else if (actionId === UrlsCrawlerActionId.YouTube) {
    saveYouTubeApifyOutput(output, crawCompleted)
  } else if (actionId === UrlsCrawlerActionId.YouTubeVideo) {
    saveYouTubeVideoApifyOutput(output, crawCompleted)
  }
}

const getUrlCrawlerDataRecord = (): Record<string, UrlCrawlerData> => {
  const list: UrlCrawlerData[] = JSON.parse(
    localStorageProvider.getItem(`fw_apify_url_crawler_data_list`) || '[]'
  )

  const record: Record<string, UrlCrawlerData> = {}
  for (const item of list) {
    if (urlCrawlerDataExpired(item) || (item.crawCompleted && !item.item)) {
      continue
    }

    const key = `${item.inputUrl}_${item.actionId}`
    record[key] = item
  }

  return record
}

const urlCrawlerDataExpired = (data: UrlCrawlerData): boolean => {
  if (!data || !data.expiresIn) {
    return true
  }
  const expiresIn = data.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}

// cache url data
const runUrlsCacheCrawler = async (params: {
  parameters: any
  actionId: string
}): Promise<ApifyOutput> => {
  const { parameters, actionId } = params
  let inputUrls: string[] = []
  if (actionId === UrlsCrawlerActionId.Tiktok) {
    inputUrls = parameters.postURLs
  } else if (actionId === UrlsCrawlerActionId.Instagram) {
    inputUrls = parameters.directUrls
  } else if (actionId === UrlsCrawlerActionId.YouTube) {
    inputUrls = parameters.startUrls?.map((item) => {
      return item.url
    })
  } else if (actionId === UrlsCrawlerActionId.YouTubeVideo) {
    inputUrls = parameters.startUrls
  }
  const record = getUrlCrawlerDataRecord()

  // 1. urls unstarted
  const unStartedInputUrls = inputUrls.filter((inputUrl) => {
    const key = `${inputUrl}_${actionId}`

    return !record[key]
  })

  if (unStartedInputUrls.length) {
    let unStartedParameters = {
      ...parameters
    }
    if (actionId === UrlsCrawlerActionId.Tiktok) {
      unStartedParameters = {
        ...unStartedParameters,
        postURLs: unStartedInputUrls
      }
    } else if (actionId === UrlsCrawlerActionId.Instagram) {
      unStartedParameters = {
        ...unStartedParameters,
        directUrls: unStartedInputUrls
      }
    } else if (actionId === UrlsCrawlerActionId.YouTube) {
      unStartedParameters = {
        ...unStartedParameters,
        startUrls: unStartedInputUrls.map((item) => {
          return {
            url: item,
            method: 'GET'
          }
        })
      }
    } else if (actionId === UrlsCrawlerActionId.YouTubeVideo) {
      unStartedParameters = {
        ...unStartedParameters,
        startUrls: unStartedInputUrls
      }
    }

    await runActorAndGetResultsSync(
      {
        parameters: unStartedParameters,
        actionId
      },
      true
    )
  }

  // 2. urls in progress
  const inProgressInputUrls = inputUrls.filter((inputUrl) => {
    const key = `${inputUrl}_${actionId}`
    const data = record[key]

    return data && !data.crawCompleted
  })

  if (inProgressInputUrls.length) {
    for (const inputUrl of inProgressInputUrls) {
      const key = `${inputUrl}_${actionId}`
      const data = record[key]
      await runActorAndGetResultsSync(
        {
          runId: data.runId,
          datasetId: data.datasetId,
          actionId,
          parameters
        },
        true
      )
    }
  }

  // 3. merge res
  const finalRecord = getUrlCrawlerDataRecord()
  const outputRes: ApifyOutput = {
    parameters,
    actionId,
    items: inputUrls?.map((inputUrl) => {
      const key = `${inputUrl}_${actionId}`
      const data = finalRecord[key]

      return data.item
    })
  }

  return outputRes
}

export const runTiktokUrlsCrawler = async (
  urls: string[]
): Promise<ApifyOutput> => {
  return await runUrlsCacheCrawler({
    parameters: {
      postURLs: urls,
      resultsPerPage: 100,
      shouldDownloadCovers: false,
      shouldDownloadSlideshowImages: false,
      shouldDownloadSubtitles: false,
      shouldDownloadVideos: true,
      videoKvStoreIdOrName: 'tiktok'
    },
    actionId: UrlsCrawlerActionId.Tiktok
  })
}

export const runTiktokHashtagCrawler = async (
  hashtag: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      excludePinnedPosts: false,
      hashtags: [hashtag],
      resultsPerPage: APIFY_RESULTS_PER_PAGE,
      shouldDownloadCovers: false,
      shouldDownloadSlideshowImages: false,
      shouldDownloadSubtitles: false,
      shouldDownloadVideos: false
    },
    actionId: 'clockworks~free-tiktok-scraper'
  })
}

export const runTiktokUsernameCrawler = async (
  username: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      excludePinnedPosts: false,
      profiles: [`@${username}`],
      resultsPerPage: APIFY_RESULTS_PER_PAGE,
      shouldDownloadCovers: false,
      shouldDownloadSlideshowImages: false,
      shouldDownloadSubtitles: false,
      shouldDownloadVideos: false
    },
    actionId: 'clockworks~free-tiktok-scraper'
  })
}

export const runInstagramUrlsCrawler = async (
  urls: string[]
): Promise<ApifyOutput> => {
  return await runUrlsCacheCrawler({
    parameters: {
      addParentData: false,
      directUrls: urls,
      enhanceUserSearchWithFacebookPage: false,
      isUserTaggedFeedURL: false,
      resultsLimit: 200,
      resultsType: 'posts',
      searchLimit: 1,
      searchType: 'hashtag'
    },
    actionId: UrlsCrawlerActionId.Instagram
  })
}

export const runInstagramHashtagCrawler = async (
  hashtag: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      hashtags: [hashtag],
      resultsLimit: APIFY_RESULTS_PER_PAGE
    },
    actionId: 'apify~instagram-hashtag-scraper'
  })
}

export const runInstagramUsernameCrawler = async (
  username: string,
  datasetId?: string
): Promise<ApifyOutput> => {
  return await runActorAndGetResultsSync({
    datasetId: datasetId,
    parameters: {
      resultsLimit: APIFY_RESULTS_PER_PAGE,
      skipPinnedPosts: false,
      username: [username]
    },
    actionId: 'apify~instagram-post-scraper'
  })
}

export const runYouTubeUrlsCrawler = async (
  urls: string[]
): Promise<{ mediaMetaData: ApifyOutput; mediaUrlData: ApifyOutput }> => {
  const [mediaMetaData, mediaUrlData] = await Promise.all([
    runUrlsCacheCrawler({
      parameters: {
        downloadSubtitles: false,
        hasCC: false,
        hasLocation: false,
        hasSubtitles: false,
        is360: false,
        is3D: false,
        is4K: false,
        isBought: false,
        isHD: false,
        isHDR: false,
        isLive: false,
        isVR180: false,
        maxResultStreams: 0,
        maxResults: 10,
        maxResultsShorts: 0,
        preferAutoGeneratedSubtitles: false,
        saveSubsToKVS: false,
        startUrls: urls?.map((item) => {
          return {
            url: item,
            method: 'GET'
          }
        }),
        subtitlesLanguage: 'any',
        subtitlesFormat: 'srt'
      },
      actionId: UrlsCrawlerActionId.YouTube
    }),
    runUrlsCacheCrawler({
      parameters: {
        includeFailedVideos: false,
        maxConcurrency: 10,
        maxRequestRetries: 0,
        proxy: {
          useApifyProxy: true,
          apifyProxyGroups: ['RESIDENTIAL']
        },
        startUrls: urls,
        useFfmpeg: false
      },
      actionId: UrlsCrawlerActionId.YouTubeVideo
    })
  ])

  return { mediaMetaData, mediaUrlData }
}

export const defaultApifyDataSetIdExpiresIn = 24 * 60 * 60 * 1000

export type ApifyDataset = {
  id?: string
  expiresIn?: number
  type?: 'username' | 'hashtag'
  value?: string
}

export const saveDataset = (
  dataset: ApifyDataset,
  sourceFrom: 'tiktok' | 'instagram'
): void => {
  localStorageProvider.setItem(
    `fw_apify_${sourceFrom}_${dataset.type}_${dataset.value}`,
    JSON.stringify(dataset)
  )
}

export const getDataset = (
  dataset: ApifyDataset,
  sourceFrom: 'tiktok' | 'instagram'
): ApifyDataset => {
  return JSON.parse(
    localStorageProvider.getItem(
      `fw_apify_${sourceFrom}_${dataset.type}_${dataset.value}`
    ) || '{}'
  )
}

export const isDateSetExpired = (dataset: ApifyDataset): boolean => {
  if (!dataset || !dataset.id || !dataset.expiresIn) {
    return true
  }
  const expiresIn = dataset.expiresIn
  const date = new Date()

  return expiresIn <= date.getTime() + 10 * 60 * 1000
}
