import React from 'react'

import { ThemeProvider } from '@src/common/contexts/ThemeContext'
import { RouterProvider } from 'react-router-dom'

import createRouter from './createRouter'

export default function App() {
  const router = createRouter()
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}
