import {
  createAction,
  createSlice,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import sendAmplitudeTrackingEvent, {
  setAmplitudeDevice,
  setAmplitudeUser
} from '@src/utils/amplitudeTracking'
import sendVisitorEventPixelTracking from '@src/utils/pixelTracking'
import { sessionStorageProvider } from '@src/utils/storageProvider'
import { TRACKING_EVENTS } from '@src/utils/tracking'

import api, { API_URL } from '../common/utils/api'
import { fetchProfile } from './profile'

const SLICE_NAME = 'session'

const initialState = {
  authenticated: false,
  username: ''
}

const slice: Slice<
  typeof initialState,
  SliceCaseReducers<typeof initialState>,
  typeof SLICE_NAME
> = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    createSessionSuccess(state) {
      state.authenticated = true
    },
    setUserName(state, action) {
      state.username = action.payload
    }
  }
})

export default slice.reducer

export const { createSessionSuccess, setUserName } = slice.actions

const createSessionRequest = createAction('session/createSessionRequest')
const createSessionFailure = createAction('session/createSessionFailure')

export function createSession() {
  return async (dispatch) => {
    try {
      dispatch(createSessionRequest())
      const response = await api.post('/sessions/refresh')
      const { user, token } = response.data
      await api.setToken(token)
      dispatch(setUserName(user.username))
      setAmplitudeUser(`${user.id}`)
      setAmplitudeDevice()
      const res = await dispatch(fetchProfile())
      const userData = res.data
      const trackedSession = sessionStorageProvider.getItem('session-active')
      if (!userData.is_super_user && !trackedSession) {
        sendAmplitudeTrackingEvent({
          event: TRACKING_EVENTS.BUSINESS_PORTAL_LOGIN_EXTERNAL
        })
        sendVisitorEventPixelTracking({
          event: TRACKING_EVENTS.BUSINESS_PORTAL_LOGIN_EXTERNAL
        })
        sessionStorageProvider.setItem('session-active', 'true')
      }
      dispatch(createSessionSuccess({}))

      return res
    } catch (error) {
      if (error?.status === 401) {
        api.removeToken()
      }
      dispatch(createSessionFailure())
    }
  }
}

export function logout() {
  return async () => {
    await api.removeToken()
    setAmplitudeUser(null)
    window.location.href = `${API_URL}/auth/logout?redirect_to=${window.location.origin}`
  }
}
