import React, { useEffect, useState, useMemo, useCallback } from 'react'

import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { Box, Flex } from '@src/components/EmotionLayout'
import { ImpressionContainer } from '@src/components/ImpressionContainer'
import { PLG_PLANS, SIGMA_INSIGHTS } from '@src/constants'
import { usePixelAmpTracking } from '@src/hooks'
import { fetchSigmaUrl, clearSigmaUrl } from '@src/redux/sigmaEmbed'
import { Spin } from 'antd'
import IframeResizer from 'iframe-resizer-react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { trackingMap, trackingSubTabMap } from './constants'

type IProps = {
  level: string
  id: string
}

const EmbedIframe: React.FC<IProps> = ({ level, id }: IProps) => {
  const [loading, setLoading] = useState(true)
  const { channelId, businessId } = useParams()
  const sigmaUrl = useAppSelector((state) => state.sigmaEmbed.sigmaUrlByLevel)
  const subscription = useAppSelector(
    (state) => state.subscriptions.subscriptionsByBusinessId[businessId]
  )
  const businessSubscription = useAppSelector(
    (state) => state.business.businesses[businessId]?.subscription
  )
  const dispatch = useDispatch()
  const iframeId = uuidv4()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const planId = subscription?.subscription_plan?.plan_id
  const isFreePlan = planId === PLG_PLANS.PILOT || planId === PLG_PLANS.STARTER
  const hasLivestream = businessSubscription?.can_create_live_stream

  const sendTracking = useCallback(() => {
    if (!trackingMap[level]) return
    pixelAmpTracking(trackingMap[level], { tab: trackingSubTabMap[level] })
  }, [level, pixelAmpTracking])

  const mapDashboardId = useMemo(
    () => ({
      [SIGMA_INSIGHTS.VIDEO_CHANNEL_SHORT_VIDEO]: isFreePlan
        ? '1-2wniaKBXm9XrsP890kLDtS'
        : '1-9CHN0jBF2XQFoiunpSoe0',
      [SIGMA_INSIGHTS.VIDEO_CHANNEL_OVERVIEW]: hasLivestream
        ? '1-2hjT7ik4wQ9pJqeTxtNLZ1'
        : '1-wyih3r5mq07ORL4NliCd0',
      [SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL]: isFreePlan
        ? '1-4erGGOtds8TdZpRUxBijbW'
        : '1-5a1qIPJXMRwHwjgF0eaF7G',
      [SIGMA_INSIGHTS.VIDEO_CHANNEL_LIVESTREAM]: '1-3krEo1OdrLUEdJ6etzP4vM',
      [SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO]: '1-1ety1Whq8iZPwilXFdc6At',
      [SIGMA_INSIGHTS.VIDEO_LIVESTREAM]: '1-4yGLTxF5OxwQoyL7e6ynKk',
      [SIGMA_INSIGHTS.OTO_BUSINESS_GENERAL]: '1-2nMg0MDz970Vi6lWX6S3HH',
      [SIGMA_INSIGHTS.OTO_BUSINESS_PERFORMANCE]: '1-560qQOgG2eM9YS8zHI6J4Z',
      [SIGMA_INSIGHTS.OTO_BUSINESS_REQUESTS]: '1-1BPAHXimZVtL2ggd3Md5r2',
      [SIGMA_INSIGHTS.AVA_BUSINESS]: '1-5l5VF2wvGAZEnMAdfZt4FP',
      [SIGMA_INSIGHTS.AVA_DOMAIN_ASSISTANT]: '1-5l5VF2wvGAZEnMAdfZt4FP'
    }),
    [isFreePlan, hasLivestream]
  )

  const iframeCss = useMemo(
    () => css`
      border: none;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      opacity: ${loading ? 0 : 1};
    `,
    [loading]
  )

  useEffect(() => {
    dispatch(
      fetchSigmaUrl({
        businessId,
        channelId,
        dashboardId: mapDashboardId[level],
        params: {},
        level,
        id
      })
    )

    return () => {
      dispatch(clearSigmaUrl({ level, id }))
    }
  }, [businessId, channelId, dispatch, id, level, mapDashboardId])

  useEffect(() => {
    const sigmaMessageHandler = (event) => {
      if (event.data.type === 'workbook:loaded') {
        setLoading(false)
      }
    }

    window.addEventListener('message', sigmaMessageHandler)

    return () => {
      window.removeEventListener('message', sigmaMessageHandler)
    }
  }, [])

  return (
    <Box height="100%">
      {loading && (
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          right={0}
          top={200}
        >
          <Spin size="large" />
        </Flex>
      )}
      <ImpressionContainer trackingFn={sendTracking} />
      <IframeResizer
        height="100%"
        id={iframeId}
        heightCalculationMethod="lowestElement"
        scrolling={false}
        loading="eager"
        src={sigmaUrl[level][id]}
        width="100%"
        widthCalculationMethod="rightMostElement"
        css={iframeCss}
      />
    </Box>
  )
}

export default EmbedIframe
