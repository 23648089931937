import React, { useEffect, useMemo } from 'react'

import { useAppSelector } from '@src/app/hooks'
import LoadingIndicator from '@src/components/LoadingIndicator'
import { REQUEST_STATUS } from '@src/constants'
import { useBusinessId } from '@src/hooks'
import { useOnboarding } from '@src/hooks/Onboarding/useOnboarding'
import { useBusinessEntryPoint } from '@src/hooks/useBusinessEntryPoint'
import useOAuthApp from '@src/hooks/useOAuthApp'
import {
  PERMISSIONS,
  useRolesAndPermissions
} from '@src/hooks/useRolesAndPermissions'
import { useToast } from '@src/hooks/useToast'
import { fetchOAuthApps } from '@src/modules/app/redux/oauthApp'
import { fetchSmartButtons } from '@src/modules/automation/redux/smartButton'
import { fetchChatChannels } from '@src/modules/one-to-one/redux/chatChannel'
import {
  fetchBusiness,
  fetchBusinesses,
  getBusinessActivationStatus
} from '@src/redux/business'
import {
  fetchBusinessStore,
  fetchBusinessStores
} from '@src/redux/businessStore'
import { fetchChannels } from '@src/redux/channel'
import {
  fetchFavoriteBusiness,
  removeFavoriteBusiness,
  renameFavoriteBusiness
} from '@src/redux/favoriteBusiness'
import {
  CURRENT_SUBSCRIPTION_DEACTIVE_ERROR_CODE,
  fetchPaymentSubscriptions,
  fetchPlans
} from '@src/redux/subscriptions'
import { setOAuthApp } from '@src/redux/user'
import { makeGetRequest } from '@src/selectors/request'
import { isPLGBusiness } from '@src/selectors/subscription'
import {
  businessList,
  combineLocalStorageList,
  getStorage,
  setStorage
} from '@src/utils/localStorage'
import { sessionStorageProvider } from '@src/utils/storageProvider'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

export const BusinessContext = React.createContext()

export function BusinessProvider({ children }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { errorToast } = useToast()
  const username = useSelector((state) => state.session.username || '')
  const localBusinessList = getStorage(businessList, [username])
  let businessIds = useSelector((state) => state.business.ids)
  if (localBusinessList)
    businessIds = combineLocalStorageList(localBusinessList, businessIds)
  const businessId = useBusinessId(businessIds)
  const { oauthDefaultBusinessId, oauthDefaultBusinessStoreId } = useOAuthApp()
  const { userCan } = useRolesAndPermissions()

  const getRequest = useMemo(makeGetRequest, [])
  const { state: fetchBusinessesRequest } = useSelector((state) =>
    getRequest(state, 'business/fetchBusinesses')
  )
  const { state: fetchBusinessRequestState } = useSelector((state) =>
    getRequest(state, 'business/fetchBusiness')
  )
  const { state: channelFetchChannels } = useSelector((state) =>
    getRequest(state, 'channel/fetchChannels')
  )
  const channelIds = useSelector(
    (state) => state.channel.idsByBusiness[businessId] || []
  )
  const { state: fetchChatChannelsState } = useSelector((state) =>
    getRequest(state, 'chatChannel/fetchChatChannels')
  )
  const chatChannelIds = useAppSelector((state) => state.chatChannel.ids || [])

  const role = useSelector(
    (state) => state.business?.businesses[businessId]?.role
  )

  const user = useAppSelector((state) => state.profile.user)
  const business = useAppSelector(
    (state) => state.business?.businesses[businessId]
  )

  const businessSubscription = useAppSelector(
    (state) => state.subscriptions.subscriptionsByBusinessId[businessId]
  )

  const { getBusinessEntryPoint } = useBusinessEntryPoint()
  const {
    initializeOnboarding,
    isOnboardingQuestionnaireComplete
  } = useOnboarding()

  useEffect(() => {
    if (business) {
      if (business.id) {
        dispatch(fetchFavoriteBusiness(user.encoded_id))
        dispatch(renameFavoriteBusiness(user.encoded_id, business))
      } else {
        dispatch(removeFavoriteBusiness(user.encoded_id, businessId))
      }
    }
  }, [dispatch, user, business, businessId])

  useEffect(() => {
    if (role && businessId) {
      if (userCan(PERMISSIONS.BUSINESS_APPS_VIEW, role))
        dispatch(fetchOAuthApps(businessId))
    }
    // TODO: CMS-300
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, dispatch, role])

  useEffect(() => {
    dispatch(fetchBusinesses())
  }, [businessId, dispatch])

  useEffect(() => {
    const initBusInfo = async () => {
      const sub = await dispatch(fetchPaymentSubscriptions(businessId))
      if (sub?.data?.error) {
        errorToast(
          sub.status === CURRENT_SUBSCRIPTION_DEACTIVE_ERROR_CODE
            ? t(
                'The business you are trying to access is deactivated, please reach out to your assigned Customer Success Manager for a renewal.'
              )
            : t('Access denied: You are not authorized to view this business.')
        )
        await dispatch(removeFavoriteBusiness(user.encoded_id, businessId))
        const localStorageId = getStorage(businessList, [username])
        if (localStorageId === businessId) {
          setStorage(businessList, [username], null)
        }
        navigate('/businesses')
      } else {
        dispatch(fetchBusiness(businessId))
        dispatch(fetchChannels(businessId))
        dispatch(fetchBusinessStores(businessId, null))
        dispatch(fetchChatChannels(businessId))
        dispatch(fetchPlans(businessId))
        dispatch(fetchSmartButtons({ businessId }))
      }
    }
    if (businessId && user.encoded_id) {
      initBusInfo()
    }
  }, [dispatch, businessId, t, navigate, user, username, errorToast])

  // Setting OAuth app from session storage after iframe redirect
  useEffect(() => {
    const oauthApp = sessionStorageProvider.getItem('oauthApp')
    if (oauthApp) {
      dispatch(setOAuthApp({ oauthApp }))
    }
  }, [dispatch])

  useEffect(() => {
    if (
      businessId &&
      businessId === oauthDefaultBusinessId &&
      oauthDefaultBusinessStoreId
    ) {
      dispatch(fetchBusinessStore(businessId, oauthDefaultBusinessStoreId))
    }
  }, [
    businessId,
    oauthDefaultBusinessId,
    oauthDefaultBusinessStoreId,
    dispatch
  ])

  const hasPLGSubscription = useSelector((state) =>
    isPLGBusiness(state, businessId)
  )

  // Redirect to onboarding questionnaire if the business has a PLG
  // subscription and role is not super_user
  useEffect(() => {
    async function getActivationStatus(businessId) {
      const { data } = await getBusinessActivationStatus(businessId)
      if (data?.activation_status && hasPLGSubscription) {
        initializeOnboarding()
      }
    }

    if (businessId) {
      getActivationStatus(businessId)
    }
  }, [
    businessId,
    hasPLGSubscription,
    initializeOnboarding,
    isOnboardingQuestionnaireComplete,
    navigate,
    role
  ])

  useEffect(() => {
    const pathname = window.location.pathname

    if (
      pathname === '/packages' ||
      pathname === '/business/create' ||
      pathname.match('/invitation') ||
      pathname.match('/businesses/associate') ||
      pathname.match('/business_store_connect')
    ) {
      // Create business or invitation or associate page, no redirect
    } else if (businessIds.length && pathname === '/') {
      // The user is on the root path and we should redirect them to the first
      // business overview
      if (
        channelFetchChannels === REQUEST_STATUS.RESOLVED &&
        fetchChatChannelsState === REQUEST_STATUS.RESOLVED &&
        fetchBusinessRequestState === REQUEST_STATUS.RESOLVED
      ) {
        // Make sure all data is prepared, especially the businessData to get the subscription plan
        const routePath = getBusinessEntryPoint({
          businessId: businessId,
          channels: channelIds,
          toStoreListIfNeeded: true,
          chatChannels: chatChannelIds
        })
        navigate(routePath)
      }
    } else if (
      fetchBusinessesRequest === REQUEST_STATUS.RESOLVED &&
      !businessIds.length
    ) {
      navigate('/packages')
    }
  }, [
    businessId,
    businessIds,
    fetchBusinessesRequest,
    fetchBusinessRequestState,
    channelFetchChannels,
    channelIds,
    fetchChatChannelsState,
    chatChannelIds,
    getBusinessEntryPoint,
    navigate
  ])

  return (
    <BusinessContext.Provider value={businessId}>
      {/* if we have a businessId and the business info has been fetched, or if we don't have a
      businessId, render the children, otherwise render a loading indicator */}
      {(businessId && business && businessSubscription) || !businessId ? (
        children
      ) : (
        <LoadingIndicator />
      )}
    </BusinessContext.Provider>
  )
}

BusinessProvider.propTypes = {
  children: PropTypes.node
}
