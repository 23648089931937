import { Dispatch, createAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@src/app/store'
import api from '@src/common/utils/api'
import { convertChatChannelSegmentToV2IfNeeded } from '@src/modules/one-to-one/utils/chatChannel'

type IProps = {
  chatChannelId: string | null
  visitorSegments: ChatChannelSegment[]
  paging: Record<string, any>
  // Search
  searchVisitorSegments: ChatChannelSegment[]
  searchPaging: Record<string, any>
}

const initialState: IProps = {
  chatChannelId: null,
  visitorSegments: [],
  paging: {},
  // Search
  searchVisitorSegments: [],
  searchPaging: {}
}

const slice = createSlice({
  name: 'chatChannelSegment',
  initialState: initialState,
  reducers: {
    fetchChatChannelSegmentsSuccess(state, action) {
      const { chatChannelId, visitor_segments, paging, page } = action.payload

      // Clear segments if the segments do not belong to the chat channel
      if (chatChannelId !== state.chatChannelId) {
        state.visitorSegments = []
      }
      state.chatChannelId = chatChannelId
      state.paging = paging

      if (page) {
        const stateIds = state.visitorSegments.map((segment) => segment.id)
        visitor_segments.forEach((segment) => {
          if (!stateIds.includes(segment.id)) {
            state.visitorSegments.push(segment)
          }
        })
      } else {
        state.visitorSegments = visitor_segments
      }
    },
    searchChatChannelSegmentsSuccess(state, action) {
      const { visitor_segments, paging, page } = action.payload

      state.searchPaging = paging

      if (page) {
        const stateSearchIds = state.searchVisitorSegments.map(
          (segment) => segment.id
        )
        visitor_segments.forEach((segment) => {
          if (!stateSearchIds.includes(segment.id)) {
            state.searchVisitorSegments.push(segment)
          }
        })
      } else {
        state.searchVisitorSegments = visitor_segments
      }
    },
    fetchChatChannelSegmentSuccess(state, action) {
      const { visitor_segment } = action.payload

      const index = state.visitorSegments.findIndex(
        (item) => item.id === visitor_segment.id
      )
      if (index !== -1) {
        state.visitorSegments[index] = visitor_segment
      }
    },
    createChatChannelSegmentSuccess(state, action) {
      // New segment will appear at the top
      const { visitor_segment } = action.payload
      state.visitorSegments.unshift(visitor_segment)
    },
    updateChatChannelSegmentSuccess(state, action) {
      const { visitor_segment } = action.payload

      const index = state.visitorSegments.findIndex(
        (item) => item.id === visitor_segment.id
      )
      if (index !== -1) {
        state.visitorSegments[index] = visitor_segment
      }
    },
    deleteChatChannelSegmentSuccess(state, action) {
      const { visitorSegmentId } = action.payload

      state.visitorSegments = state.visitorSegments.filter(
        (e) => e.id !== visitorSegmentId
      )
    }
  }
})

export default slice.reducer
export const {
  fetchChatChannelSegmentsSuccess,
  searchChatChannelSegmentsSuccess,
  fetchChatChannelSegmentSuccess,
  createChatChannelSegmentSuccess,
  updateChatChannelSegmentSuccess,
  deleteChatChannelSegmentSuccess
} = slice.actions

/**
 * Fetch Chat Channel Segments
 */
const fetchChatChannelSegmentsRequest = createAction(
  'chatChannelSegment/fetchChatChannelSegmentsRequest'
)
const fetchChatChannelSegmentsFailure = createAction(
  'chatChannelSegment/fetchChatChannelSegmentsFailure'
)

export function fetchChatChannelSegments(
  businessId: string,
  chatChannelId: string,
  page?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChatChannelSegmentsRequest())
      let url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_segments/search?page_size=10&query=`
      if (page) {
        url = page
      }
      const response = await api.get(url)
      const { visitor_segments, paging = null } = response.data
      dispatch(
        fetchChatChannelSegmentsSuccess({
          chatChannelId,
          visitor_segments,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChatChannelSegmentsFailure())

      return error
    }
  }
}
/**
 * Search Chat Channel Segments
 */
const searchChatChannelSegmentsRequest = createAction(
  'chatChannelSegment/searchChatChannelSegmentsRequest'
)
const searchChatChannelSegmentsFailure = createAction(
  'chatChannelSegment/searchChatChannelSegmentsFailure'
)

export function searchChatChannelSegments(
  businessId: string,
  chatChannelId: string,
  query: string,
  page?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(searchChatChannelSegmentsRequest())
      let url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_segments/search?page_size=10&query=${query}`
      if (page) {
        url = page
      }
      const response = await api.get(url)
      const { visitor_segments, paging = null } = response.data
      dispatch(
        searchChatChannelSegmentsSuccess({
          visitor_segments,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(searchChatChannelSegmentsFailure())

      return error
    }
  }
}
/**
 * Fetch Chat Channel Segment
 */
const fetchChatChannelSegmentRequest = createAction(
  'chatChannelSegment/fetchChatChannelSegmentRequest'
)
const fetchChatChannelSegmentFailure = createAction(
  'chatChannelSegment/fetchChatChannelSegmentFailure'
)

export function fetchChatChannelSegment(
  businessId: string,
  chatChannelId: string,
  visitorSegmentId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChatChannelSegmentRequest())
      const url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_segments/${visitorSegmentId}`
      const response = await api.get(url)
      const { visitor_segment } = response.data

      // TODO: remove this convert if all segments will be migrated to V2 in the future
      const segment = convertChatChannelSegmentToV2IfNeeded(visitor_segment)
      dispatch(
        fetchChatChannelSegmentSuccess({
          visitor_segment: segment
        })
      )

      return segment
    } catch (error) {
      dispatch(fetchChatChannelSegmentFailure())

      return error
    }
  }
}

/**
 * Create Chat Channel Segment
 */
const createChatChannelSegmentRequest = createAction(
  'chatChannelSegment/createChatChannelSegmentRequest'
)
const createChatChannelSegmentFailure = createAction(
  'chatChannelSegment/createChatChannelSegmentFailure'
)

export interface ChatChannelSegmentRequest {
  name: string
  criteria: ChatChannelSegmentCriteria
}

export function createChatChannelSegment(
  businessId: string,
  chatChannelId: string,
  data: ChatChannelSegmentRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createChatChannelSegmentRequest())
      const url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_segments`
      const response = await api.post(url, data)
      const { visitor_segment } = response.data
      dispatch(
        createChatChannelSegmentSuccess({
          visitor_segment
        })
      )

      return response
    } catch (error) {
      dispatch(createChatChannelSegmentFailure())

      return error
    }
  }
}

/**
 * Update Chat Channel Segment
 */
const updateChatChannelSegmentRequest = createAction(
  'chatChannelSegment/updateChatChannelSegmentRequest'
)
const updateChatChannelSegmentFailure = createAction(
  'chatChannelSegment/updateChatChannelSegmentFailure'
)

export function updateChatChannelSegment(
  businessId: string,
  chatChannelId: string,
  visitorSegmentId: string,
  data: ChatChannelSegmentRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChatChannelSegmentRequest())
      const url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_segments/${visitorSegmentId}`
      const response = await api.patch(url, data)
      const { visitor_segment } = response.data
      dispatch(
        updateChatChannelSegmentSuccess({
          visitor_segment
        })
      )

      return response
    } catch (error) {
      dispatch(updateChatChannelSegmentFailure())

      return error
    }
  }
}

/**
 * Delete Chat Channel Segment
 */
const deleteChatChannelSegmentRequest = createAction(
  'chatChannelSegment/deleteChatChannelSegmentRequest'
)
const deleteChatChannelSegmentFailure = createAction(
  'chatChannelSegment/deleteChatChannelSegmentFailure'
)

export function deleteChatChannelSegment(
  businessId: string,
  chatChannelId: string,
  visitorSegmentId: string
) {
  return async (
    dispatch: Dispatch,
    getState: () => RootState
  ): Promise<string | any> => {
    try {
      dispatch(deleteChatChannelSegmentRequest())
      const url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_segments/${visitorSegmentId}`
      const response = await api.delete(url)
      dispatch(
        deleteChatChannelSegmentSuccess({
          visitorSegmentId
        })
      )

      // If there is paging when deleting, refresh the segment list
      // Otherwise, there may be a situation where paging exists but the current segments is empty.
      const segmentState = getState().chatChannelSegment
      if (segmentState.paging?.next) {
        dispatch(fetchChatChannelSegments(businessId, chatChannelId) as any)
      }

      return response
    } catch (error) {
      dispatch(deleteChatChannelSegmentFailure())

      return error
    }
  }
}
