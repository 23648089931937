import React from 'react'

import { AutomationRoutes } from '@src/modules/channel/routes/Automation'
import { DynamicContentRoutes } from '@src/modules/channel/routes/DynamicContent'
import { JourneyRoutes } from '@src/modules/channel/routes/Library/Journey'

export const Routes = (
  <>
    {DynamicContentRoutes}
    {AutomationRoutes}
    {JourneyRoutes}
  </>
)
