import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { AppDispatch } from '@src/app/store'
import ProductFeed from '@src/common/components/ProductFeed'
import ProductSearch from '@src/common/components/ProductSearch'
import { Box, Flex } from '@src/components/EmotionLayout'
import { STORE_PROVIDERS } from '@src/constants'
import { isMdScreen, usePixelAmpTracking } from '@src/hooks'
import { useChannelStoreList } from '@src/hooks/useChannelStoreList'
import { useSyndication } from '@src/hooks/useSyndication'
import { useToast } from '@src/hooks/useToast'
import { ProductOptions } from '@src/pages/channel/ChannelVideoUpload/components/VU.types'
import Products from '@src/pages/channel/Products/Products'
import { fetchBusinessStores, fetchProducts } from '@src/redux/businessStore'
import { fetchChannelAllStores } from '@src/redux/channelStore'
import theme from '@src/styles/theme'
import { convertOptionArray } from '@src/utils/products'
import { compareArrays } from '@src/utils/stats'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Popover, Row, Select, Tabs, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { FWButton, Label } from '../index'
import LoadingIndicator from '../LoadingIndicator'

export interface Props {
  selectedProductIds: string[]
  setSelectedProductIds?: Dispatch<SetStateAction<string[]>>
  selectedProductOptions?: ProductOptions[]
  setSelectProductOptions?: Dispatch<SetStateAction<ProductOptions[]>>
  convertAndAddProducts?: (
    products: {
      business_store_id?: string
      product_id: string
    }[],
    options?: ProductOptions[]
  ) => void
}
interface OverlayProductProps extends Props {
  onClose?: () => Dispatch<SetStateAction<boolean>> | void
  trackingProps?: {
    [key: string]: any
  }
}

const convertOptionToString = (options: ProductOptions[]) => {
  return options.map((option) =>
    option.unitId ? `${option.id}-${option.unitId}` : option.id
  )
}

const OverlayProduct: React.FC<OverlayProductProps> = ({
  selectedProductIds,
  setSelectedProductIds,
  selectedProductOptions,
  setSelectProductOptions,
  onClose,
  convertAndAddProducts,
  trackingProps = {
    context: 'short_video'
  }
}): JSX.Element => {
  const { videoId, businessId, channelId: pathChannelId } = useParams()
  const { errorToast } = useToast()
  const dispatch: AppDispatch = useDispatch()
  const video: globalLib.IVideo = useAppSelector(
    (state) => state.content.videos[videoId]
  )
  const isProductsFromLinkedStore = useSyndication(
    pathChannelId
  ).isProductsFromLinkedStore(video)

  // we need to use the video channel if isProductsFromLinkedStore is true.
  // This is required by the b2b syndication feature
  const channelId = isProductsFromLinkedStore
    ? video
      ? video?.creator?.encoded_id
      : pathChannelId
    : pathChannelId

  const [storeId, setStoreId] = useState(null)

  const existedProducts = useMemo(() => {
    if (isProductsFromLinkedStore) {
      // b2b syndication feature: product.vendor_id !== storeId,
      // product.vendor_id is marketplace store, but storeId is linked store.
      return video?.products || []
    }

    return storeId && video?.products
      ? video.products.filter((product) => product.vendor_id === storeId)
      : []
  }, [storeId, video, isProductsFromLinkedStore])

  const mdScreen = isMdScreen()
  const { TabPane } = Tabs
  const { t } = useTranslation()
  const { Text } = Typography
  const { Option } = Select
  const { pixelAmpTracking } = usePixelAmpTracking()
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState('select')

  const [selected, setSelected] = useState(
    selectedProductOptions
      ? convertOptionToString(selectedProductOptions)
      : selectedProductIds
  )

  const [selectedProducts, setSelectedProducts] = useState([])

  const channelStores = useAppSelector(
    (state) =>
      (isProductsFromLinkedStore
        ? state.channelStore.channelAllStores[channelId]
        : Object.values(state.businessStore.businessStores)) || []
  )
  const channelStoreList = useChannelStoreList(channelStores)

  const store = useMemo(() => {
    if (storeId) {
      return channelStores.find((store) => store.id === storeId)
    }

    return undefined
  }, [channelStores, storeId])

  useEffect(() => {
    if (channelStores?.length === 1) {
      setStoreId(channelStores[0].id)
    }
  }, [channelStores])

  const businessProducts = useAppSelector((state) =>
    selectedProductIds.length
      ? state.product?.productsData || []
      : state.product?.products || []
  )

  const fetchAllBusinessStores = useCallback(
    async (businessId: string, page = null) => {
      const response = (await dispatch(
        fetchBusinessStores(businessId, page, 100)
      )) as any
      const paging = response?.data?.paging
      if (paging && paging.next) {
        await fetchAllBusinessStores(businessId, paging.next)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (isProductsFromLinkedStore) {
      // for b2b feature
      dispatch(fetchChannelAllStores(businessId, channelId)).then(() =>
        setLoading(false)
      )
    } else {
      fetchAllBusinessStores(businessId).then()
    }
  }, [
    businessId,
    dispatch,
    channelId,
    isProductsFromLinkedStore,
    fetchAllBusinessStores
  ])

  useEffect(() => {
    dispatch(
      fetchProducts({
        businessId,
        storeId
      })
    ).then(() => {
      setLoading(false)
    })
  }, [businessId, dispatch, storeId])

  // When reselect the store, the product container should show existed products
  useEffect(() => {
    if (
      existedProducts &&
      existedProducts.length &&
      existedProducts[0].vendor_id === storeId
    )
      setSelected(
        selectedProductOptions
          ? convertOptionToString(selectedProductOptions)
          : selectedProductIds
      )
  }, [storeId, existedProducts, selectedProductIds, selectedProductOptions])

  useEffect(() => {
    if (isProductsFromLinkedStore && channelStores.length) {
      if (selectedProductIds.length) {
        setStoreId(channelStores[0].id)
      }
    }
  }, [channelStores, selectedProductIds, isProductsFromLinkedStore])

  useEffect(() => {
    if (!isProductsFromLinkedStore) {
      if (selectedProductIds.length && businessProducts.length) {
        const selectedProduct: globalLib.Product = businessProducts.find(
          (p) => p.id === selectedProductIds[0]
        )
        if (selectedProduct) {
          setStoreId(selectedProduct.vendor_id)
        }
      }
    }
  }, [businessProducts, selectedProductIds, isProductsFromLinkedStore])

  const products = useAppSelector((state) =>
    state.businessStore.productsByBusinessStoreId[storeId]
      ? state.businessStore.productsByBusinessStoreId[storeId].products
      : []
  )

  const checkedProducts = []
  const hashProductIds = {}
  const combineProducts = [...existedProducts, ...products]
  // products array depend on the pagination so it may have or not have products in the existedProducts
  // this function makes sure the checkedProducts has unique products of existed and selected
  combineProducts.forEach((p) => {
    if (!hashProductIds[p.id] && selectedProductIds.includes(p.id)) {
      hashProductIds[p.id] = true
      checkedProducts.push(p)
    }
  })

  const listProducts = selectedProductIds.length
    ? [
        ...checkedProducts,
        ...products.filter(
          (product) => !selectedProductIds.includes(product.id)
        )
      ]
    : products

  const next = useAppSelector((state) =>
    state.businessStore.productsByBusinessStoreId[storeId]?.paging
      ? state.businessStore.productsByBusinessStoreId[storeId].paging.next
      : null
  )

  const hasMore = !!next

  function onSelect(productId) {
    const pid =
      productId.split('-').length > 1 ? productId.split('-')[0] : productId
    // limit the selected variant of the product to 5
    const selectedVariant = selected.filter(
      (s) => s.startsWith(pid) && s.split('-').length > 1
    )
    if (selectedVariant.length >= 5) {
      errorToast(t('A product can have up to a max of 5 selected variants'))

      return
    }
    const product = listProducts.find((p) => p.id === pid)
    const newSelected = selected
      .filter((s) => (pid === productId ? !s.startsWith(productId) : s !== pid))
      .concat([productId])
    setSelected(newSelected)
    if (productId.split('-').length > 1) {
      productId = productId.split('-')[0]
    }
    setSelectedProducts([...selectedProducts, product || {}])
  }
  function onUnselect(productId) {
    const pid =
      productId.split('-').length > 1 ? productId.split('-')[0] : productId
    const newSelected = selected.filter((s) =>
      productId === pid ? !s.startsWith(pid) : s !== pid && s !== productId
    )
    setSelected(newSelected)
    setSelectedProducts(selectedProducts.filter((p) => p.id !== productId))
  }
  function handleUpdate() {
    const selectedIds = selected.map((p) => {
      const split = p.split('-')
      if (split.length > 1) {
        const [pid] = split

        return pid
      }

      return p
    })
    setSelectedProductIds(selectedIds)
    const selectOptions: ProductOptions[] = selected.map((p) => {
      const split = p.split('-')
      if (split.length > 1) {
        const [pid, vid] = split

        return {
          id: pid,
          unitId: vid,
          businessStoreId: storeId
        }
      }

      return {
        id: p,
        businessStoreId: storeId
      }
    })
    setSelectProductOptions?.(selectOptions)

    if (selectOptions) {
      const productOptions: {
        business_store_id?: string
        product_id: string
      }[] = selectOptions
        ? convertOptionArray(selectOptions)
        : selected.map((p) => {
            return { product_id: p }
          })

      convertAndAddProducts?.(productOptions, selectOptions)
    }
    onClose?.()

    let trackingEvent: string
    switch (trackingProps.context) {
      case 'bulk_upload':
        trackingEvent = TRACKING_EVENTS.BUSINESS_BULK_ADD_PRODUCT_OVERLAY
        break
      case 'instagram_personal':
      case 'instagram_business':
      case 'instagram_link':
        trackingEvent = TRACKING_EVENTS.BUSINESS_IG_ADD_PRODUCT_OVERLAY
        break
      case 'tiktok_link':
        trackingEvent = TRACKING_EVENTS.BUSINESS_TIKTOK_ADD_PRODUCT_OVERLAY
        break
      default:
        trackingEvent = TRACKING_EVENTS.BUSINESS_ADD_PRODUCT_OVERLAY
    }

    !selectedProductIds.length &&
      pixelAmpTracking(trackingEvent, {
        products: selectedProducts.map((product) => ({
          _product_id: product.id,
          _unit_id: product.product_units[0]?.id,
          unit_amount: product.product_units?.length,
          currency_code: product.product_currency,
          variant_options: product.product_units[0]?.unit_options
        }))
      })
  }

  const saveButtonDisabled = useMemo(() => {
    const currentSelectedProductIds = selectedProductOptions
      ? convertOptionToString(selectedProductOptions)
      : selectedProductIds

    const doesArrayHaveTheSameElements = selectedProductIds.length
      ? compareArrays(currentSelectedProductIds, selected)
      : !selected.length

    const areElementsInDifferentOrder = currentSelectedProductIds.reduce<boolean>(
      (acc, id, index) => {
        return acc || id !== selected?.[index]
      },
      false
    )

    return !areElementsInDifferentOrder && doesArrayHaveTheSameElements
  }, [selected, selectedProductIds, selectedProductOptions])

  return (
    <>
      {loading ? (
        <LoadingIndicator margin="20px" />
      ) : (
        <>
          <Flex alignItems="flex-start">
            <Label
              css={css`
                margin-right: 10px;
              `}
            >
              {t('Store')}
            </Label>
            <Popover
              placement="bottomLeft"
              content={
                <div style={{ maxWidth: '350px' }}>
                  <Text
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    {t('Store selection')}
                  </Text>
                  <br />
                  <br />
                  <Text>
                    {t(
                      'Each video can have products from a single store. Changing stores will remove all the products you have previously added from another store.'
                    )}
                  </Text>
                </div>
              }
            >
              <InfoCircleOutlined
                css={css`
                  margin-top: 3px;
                `}
              />
            </Popover>
          </Flex>
          <Flex>
            {storeId && store ? (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="10px"
                width="100%"
              >
                <Box>
                  <Text
                    css={css`
                      margin-right: 10px;
                    `}
                  >
                    {store.name}
                  </Text>
                  <FWButton
                    onClick={() => {
                      setStoreId(null)
                      setSelected([])
                    }}
                    disabled={loading || channelStores.length === 1}
                  >
                    {t('Change')}
                  </FWButton>
                </Box>
              </Flex>
            ) : (
              <Select<string, { value: string; children: any }>
                css={css`
                  width: 160px;
                  margin-bottom: 10px;
                `}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={storeId}
                onChange={(value) => setStoreId(value)}
                placeholder={t('Select a store...')}
              >
                {channelStoreList.map((b) => (
                  <Option key={b.key} value={b.key}>
                    {b.value}
                  </Option>
                ))}
              </Select>
            )}
          </Flex>
          <Tabs
            defaultActiveKey="select"
            activeKey={tab}
            onTabClick={(key) => setTab(key)}
            destroyInactiveTabPane={true}
          >
            <TabPane tab={t('Select products')} key="select">
              <Row>
                <p>{t('Your imported products')}</p>
              </Row>
              {businessId && storeId ? (
                <Flex width="100%" flexDirection="column" minHeight="auto">
                  {!!channelStores.length && (
                    <Flex mb={10}>
                      <ProductSearch
                        businessId={businessId}
                        storeId={storeId}
                        channelId={channelId}
                        type={
                          isProductsFromLinkedStore
                            ? 'linkedChannel'
                            : undefined
                        }
                      />
                    </Flex>
                  )}
                  <Products
                    products={listProducts}
                    nextAction={async () => {
                      await dispatch(
                        fetchProducts({
                          businessId: businessId,
                          storeId: storeId,
                          page: next
                        })
                      )
                    }}
                    hasMore={hasMore}
                    onHighlight={(id) => {
                      selected.includes(id) ||
                      selected.find((s) => s.startsWith(id))
                        ? onUnselect(id)
                        : onSelect(id)
                    }}
                    needVariant={true}
                    selectLimit={999} // Virtually unlimited number of products
                    selected={selected}
                    highlightedProductIds={selected}
                    maxHeight={mdScreen ? '500px' : 'auto'}
                  />
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  border={`solid 1px ${theme.gray20}`}
                  px="25"
                  py="100"
                  minHeight={mdScreen ? '500' : 'auto'}
                >
                  <Box
                    as="img"
                    src="/store_gray.png"
                    width={180}
                    height={180}
                    minWidth={180}
                  />
                  <Text
                    css={css`
                      margin-top: 10px;
                    `}
                    type="secondary"
                  >
                    {t('Please pick a store to choose products from.')}
                  </Text>
                </Flex>
              )}
            </TabPane>
            {Object.values(channelStores).filter(
              (b: globalLib.BusinessStore) =>
                b.provider === STORE_PROVIDERS.BOUTIR
            ).length ? (
              <TabPane tab={t('Import products')} key="import">
                <ProductFeed
                  onAdd={(id) => {
                    setSelected([...selected, id])
                    setTab('select')
                  }}
                />
              </TabPane>
            ) : null}
          </Tabs>
          <Flex justifyContent="flex-end" mt="10px">
            <FWButton
              type="primary"
              disabled={saveButtonDisabled}
              onClick={handleUpdate}
            >
              {selectedProductIds.length ? t('Update') : t('Add')}
            </FWButton>
          </Flex>
        </>
      )}
    </>
  )
}

export default OverlayProduct
