import { combineReducers } from '@reduxjs/toolkit'
import * as oneToOneReducers from '@src/modules/one-to-one/redux'
import activationStatus from '@src/redux/activationStatus'
import websocket from '@src/redux/websocket'

import * as common from '../common/redux'
import * as oauthAppReducers from '../modules/app/redux'
import * as automation from '../modules/automation/redux'
import * as avaReducers from '../modules/ava/redux'
import * as channelReducers from '../modules/channel/redux'
import flows from '../modules/channel/redux/Library/Journey/flows'
import * as liveChatReducers from '../modules/live-chat/redux'
import * as searchReducers from '../modules/search/redux'
import * as settingsReducers from '../modules/settings/redux'
import * as showroomReducers from '../modules/showroom/redux'
import * as storeReducers from '../modules/store/redux'
import * as webhookReducers from '../modules/webhook/redux'
import asyncSourceImporter from '../redux/asyncSourceImporter'
import autoEmbed from '../redux/autoEmbed'
import billing from '../redux/billing'
import bulkVideoSelection from '../redux/bulkVideoSelection'
import business from '../redux/business'
import businessStore from '../redux/businessStore'
import channel from '../redux/channel'
import channelStore from '../redux/channelStore'
import consentRequests from '../redux/consentRequests'
import content from '../redux/content'
import display from '../redux/display'
import favoriteBusiness from '../redux/favoriteBusiness'
import favoriteChannel from '../redux/favoriteChannel'
import fbAuth from '../redux/fbAuth'
import geoLocation from '../redux/geoLocation'
import googleAuth from '../redux/googleAuth'
import igAuth from '../redux/igAuth'
import igCrawlerImporter from '../redux/igCrawlerImporter'
import igHashtagImporter from '../redux/igHashtagImporter'
import igImporter from '../redux/igImporter'
import insight from '../redux/insight'
import insightAnnotations from '../redux/insightAnnotations'
import livestream from '../redux/livestream'
import marketplace from '../redux/marketplace'
import mycontent from '../redux/mycontent'
import payment from '../redux/payment'
import playlists from '../redux/playlist'
import product from '../redux/product'
import profile from '../redux/profile'
import request from '../redux/request'
import session from '../redux/session'
import shopMinis from '../redux/shopMinis'
import sigmaEmbed from '../redux/sigmaEmbed'
import sourceImporter from '../redux/sourceImporter'
import storeProduct from '../redux/storeProduct'
import subscriptions from '../redux/subscriptions'
import tiktokImporter from '../redux/tiktokImporter'
import tkAuth from '../redux/tkAuth'
import ui from '../redux/ui'
import user from '../redux/user'
import video from '../redux/video'
import videoUpload from '../redux/videoUpload'

export default combineReducers({
  activationStatus,
  ...channelReducers,
  automation,
  autoEmbed,
  billing,
  bulkVideoSelection,
  business,
  businessStore,
  channel,
  channelStore,
  content,
  ...storeReducers,
  display,
  ...common,
  favoriteBusiness,
  favoriteChannel,
  flows,
  geoLocation,
  fbAuth,
  googleAuth,
  igHashtagImporter,
  igCrawlerImporter,
  tiktokImporter,
  sourceImporter,
  asyncSourceImporter,
  igAuth,
  igImporter,
  insight,
  insightAnnotations,
  livestream,
  marketplace,
  ...settingsReducers,
  mycontent,
  ...oauthAppReducers,
  ...oneToOneReducers,
  ...avaReducers,
  ...automation,
  payment,
  playlists,
  product,
  profile,
  request,
  ui,
  ...searchReducers,
  ...liveChatReducers,
  session,
  shopMinis,
  ...showroomReducers,
  sigmaEmbed,
  storeProduct,
  subscriptions,
  user,
  video,
  videoUpload,
  ...webhookReducers,
  websocket,
  tkAuth,
  consentRequests
})
