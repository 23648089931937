import React, { useEffect, useMemo, useState } from 'react'

import {
  LogoutOutlined,
  NotificationOutlined,
  RightCircleOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import { SideBarItemText } from '@src/common/components/Sidebar/SideBarItemText'
import { useNabooFeatureFlag } from '@src/common/hooks/useFeatureFlag'
import { Box, Flex } from '@src/components/EmotionLayout'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuList
} from '@src/components/Menu'
import { GITHUB_JSON_URL } from '@src/constants'
import { useSpace } from '@src/hooks'
import { useBusinessEntryPoint } from '@src/hooks/useBusinessEntryPoint'
import useOAuthApp from '@src/hooks/useOAuthApp'
import usePixelAmpTracking from '@src/hooks/usePixelAmpTracking'
import BusinessMenuItem from '@src/modules/profile/components/BusinessMenuItem'
import LocalBusinessMenuItem from '@src/modules/profile/components/LocalBusinessMenuItem'
import { logout } from '@src/redux/session'
import { setHasNewNotification } from '@src/redux/ui'
import { businessList, setStorage } from '@src/utils/localStorage'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Avatar, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

export interface ProfileButtonProps {
  businessId: string
  onClickLink: () => void
  expand: boolean
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({
  businessId,
  onClickLink,
  expand
}) => {
  const { isOAuthAppEmbed } = useOAuthApp()

  const { pixelAmpTracking } = usePixelAmpTracking()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const smallSpace = useSpace('small')
  const user = useAppSelector((state) => state.profile.user)
  const username = useAppSelector(
    (state) => state.profile?.user?.username || ''
  )
  const businessIds = useAppSelector((state) => state.business.ids)
  const fetchedBusinessIds = useAppSelector((state) => state.business.ids)
  const businessPaging = useAppSelector((state) => state.business.paging)

  const nabooFF = useNabooFeatureFlag()

  const { switchBusiness } = useBusinessEntryPoint()

  const favoriteBusinessList = useAppSelector(
    (state) => state.favoriteBusiness.favoriteBusinessList
  )

  const [displayFavoriteBusinessList, displayBusinessIds] = useMemo(() => {
    let fbl = favoriteBusinessList
    let bids = businessIds

    if (fbl.length) {
      if (fbl.length > 9) {
        bids = []
        fbl = fbl.slice(0, 10)
      } else {
        bids = bids.filter((businessId) => {
          const exist = fbl.find((item) => {
            return item.id === businessId
          })

          return !exist
        })
        bids = bids.slice(0, 10 - fbl.length)
      }
    }

    if (businessId) {
      fbl = fbl.filter((item) => {
        return item.id !== businessId
      })
      bids = bids.filter((bid) => {
        return bid !== businessId
      })
    }

    return [fbl, bids]
  }, [favoriteBusinessList, businessIds, businessId])

  const handleClickProfile = () => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_USER_PROFILE)
  }

  const handleClickLogout = () => {
    dispatch(logout())
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_USER_LOGOUT)
  }

  const handleSelectBusiness = async (businessId: string) => {
    onClickLink()
    setStorage(businessList, [username], businessId)

    // Start Entry Point Flow
    // Favorited, but business data has not yet been obtained
    // Only happens when favorites business is on the second pagination
    switchBusiness(businessId, !fetchedBusinessIds.includes(businessId))
  }
  const language = useMemo(() => {
    const lang =
      localStorage.getItem('i18nextLng') || navigator.language || 'en'

    return lang
  }, [])

  const hasNewNotifications = useAppSelector(
    (state) => state.ui.hasNewNotifications
  )
  const [showNotificationButton, setShowNotificationButton] = useState(false)

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(GITHUB_JSON_URL)
        if (!response.ok) throw new Error('Failed to fetch notifications')

        const data = await response.json()
        const notificationList =
          data[language] ?? data[language?.split('-')?.[0]] ?? data['en']

        if (!notificationList || notificationList.length === 0) {
          setShowNotificationButton(false)
        } else {
          // Get read notifications from localStorage
          const readNotificationsKey = `readNotifications_${user?.id}_${language}`
          const storedReadNotifications = localStorage.getItem(
            readNotificationsKey
          )
          const readNotifications = storedReadNotifications
            ? JSON.parse(storedReadNotifications)
            : []

          const hasUnread = notificationList.some(
            (notif) => !readNotifications.includes(notif.id)
          )
          dispatch(setHasNewNotification(hasUnread))
          setShowNotificationButton(true)
        }
      } catch (error) {
        setShowNotificationButton(false)
      }
    }

    if (nabooFF.cms_notification_center) {
      fetchNotifications()
    }
  }, [dispatch, language, user, nabooFF.cms_notification_center])

  // Sync read notification state to localStorage and Redux on notification click
  const handleClickNotification = () => {
    navigate('/notifications')
  }

  return (
    <>
      {!isOAuthAppEmbed && (
        <Menu>
          <MenuButton
            className="cy-user-menu-button"
            onClick={() => {
              pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_USER_AVATAR)
            }}
            style={{
              height: 40,
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: 10,
              color: '#A0ACBF',
              backgroundColor: 'transparent',
              border: 'none',
              paddingRight: 0,
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <Box position="relative" display="inline-block">
              <Avatar
                size={18}
                src={user.avatar_url}
                css={css`
                  flex-shrink: 0;
                  border-radius: 28px;
                  border: 1px solid #bfdaff;
                `}
              />
              {hasNewNotifications && (
                <Box
                  position="absolute"
                  top="0"
                  left="12px"
                  width="10px"
                  height="10px"
                  borderRadius="50%"
                  as="span"
                  style={{
                    backgroundColor: 'red'
                  }}
                  border="2px solid white"
                  zIndex={10}
                />
              )}
            </Box>
            <SideBarItemText
              text={user.name}
              show={expand}
              customStyles={css`
                margin-left: 12px;
                text-align: left;
                width: 120px;
              `}
            />
          </MenuButton>
          <MenuList style={{ display: 'flex', flexDirection: 'column' }}>
            {nabooFF.cms_oauth_app_sidebar_nav && (
              <>
                {businessPaging?.next && !isOAuthAppEmbed && (
                  <>
                    <MenuLink
                      to="/businesses"
                      as={Link as never}
                      onSelect={() => null}
                    >
                      <span>
                        <RightCircleOutlined /> {t('See All Businesses')}
                      </span>
                    </MenuLink>
                    <Divider style={{ margin: '6px 0 10px 0' }} />
                  </>
                )}
                <Box maxHeight="300" overflowY="scroll">
                  {businessId && (
                    <LocalBusinessMenuItem
                      id={businessId}
                      key={businessId}
                      selected={true}
                      onSelect={() => handleSelectBusiness(businessId)}
                    />
                  )}
                  {!!displayFavoriteBusinessList.length &&
                    displayFavoriteBusinessList.map((item) => (
                      <MenuItem
                        id={item.id}
                        key={item.id}
                        selected={businessId === item.id}
                        onSelect={() => handleSelectBusiness(item.id)}
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Box
                            css={css`
                              margin-right: 6px;
                              margin-bottom: 2px;
                            `}
                            as="img"
                            src="/icon/star.svg"
                            height="16"
                            width="16"
                          />
                          <div> {item.name}</div>
                        </Flex>
                      </MenuItem>
                    ))}
                  {!!displayBusinessIds.length &&
                    displayBusinessIds.map((id) => (
                      <BusinessMenuItem
                        id={id}
                        key={id}
                        selected={businessId === id}
                        onSelect={() => handleSelectBusiness(id)}
                      />
                    ))}
                </Box>
              </>
            )}
            <Divider style={{ margin: '6px 0 10px 0' }} />
            <MenuLink to="/profile" as={Link as never}>
              <SettingOutlined
                onClick={handleClickProfile}
                css={css`
                  margin-right: ${smallSpace};
                `}
              />
              {t('Profile')}
            </MenuLink>
            {showNotificationButton && (
              <MenuLink
                to="/notifications"
                as={Link as never}
                onClick={handleClickNotification}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Flex position="relative" alignItems="center">
                  <NotificationOutlined
                    css={css`
                      margin-right: ${smallSpace};
                    `}
                  />
                  {hasNewNotifications && (
                    <Box
                      position="absolute"
                      top="-5px"
                      right="5px"
                      width="10px"
                      height="10px"
                      borderRadius="50%"
                      as="span"
                      style={{
                        backgroundColor: 'red'
                      }}
                      border="2px solid white"
                      zIndex={10}
                    />
                  )}
                </Flex>
                {t('Updates')}
              </MenuLink>
            )}
            <MenuItem className="cy-logout-link" onSelect={handleClickLogout}>
              <LogoutOutlined
                css={css`
                  margin-right: ${smallSpace};
                `}
              />
              {t('Logout')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  )
}
