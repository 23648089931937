import React from 'react'

import loadable from '@loadable/component'
import { Routes as AppRoutes } from '@src/modules/app/routes'
import { Routes as AutomationRoutes } from '@src/modules/automation/routes'
import { AvaRoutes } from '@src/modules/ava/routes'
import { Routes as BusinessAppRoutes } from '@src/modules/business-app/routes'
import { Routes as ChannelRoutes } from '@src/modules/channel/routes'
import { OneToOneRoutes } from '@src/modules/one-to-one/routes'
import { Routes as PricingPlanRoutes } from '@src/modules/pricing-plan/routes'
import { Routes as ProfileRoutes } from '@src/modules/profile/routes'
import { Routes as SettingRoutes } from '@src/modules/settings/routes'
import { Routes as ShowroomRoutes } from '@src/modules/showroom/routes'
import { Routes as StoreRoutes } from '@src/modules/store/routes'
import { Routes as SupportRoutes } from '@src/modules/support/routes'
import { Routes as WebhookRoutes } from '@src/modules/webhook/routes'
import SigmaVideoInsights from '@src/pages/channel/ChannelVideoUpload/SigmaVideoInsights'
import SigmaLivestreamInsights from '@src/pages/livestream/Analytics/SigmaLivestreamInsights'
import { ErrorBoundary } from 'react-error-boundary'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from 'react-router-dom'

import {
  LIVESTREAM_CREATE_METHOD,
  ONBOARDING_MODES,
  VIDEO_UPLOAD_METHOD
} from '../constants'
import FallbackComponent from './FallbackComponent'
import Root from './Root'

// Lazy load page components using @loadable/component
const BusinessList = loadable(() => import('../pages/business/BusinessList'))
const OnboardBusiness = loadable(() =>
  import('../pages/business/OnboardBusiness')
)
const BusinessForm = loadable(() => import('../pages/business/BusinessForm'))
const BusinessAssociate = loadable(() => import('../pages/business/associate'))
const BusinessStoreConnect = loadable(() =>
  import('../pages/business-store-connect/Connect')
)
const BusinessStoreList = loadable(
  () => import('../pages/business-store-connect/BusinessStoreList'),
  {
    resolveComponent: (component) => component.BusinessStoreList
  }
)

const Channel = loadable(() => import('../pages/channel'))
const ChannelList = loadable(() => import('../pages/channel/ChannelList'))
const CreateChannel = loadable(() => import('../pages/channel/ChannelCreate'))
const MyContentCentral = loadable(() =>
  import('../pages/channel/MyContentCentral')
)
const Integrations = loadable(() => import('../pages/home/Integrations'), {
  resolveComponent: (component) => component.Integrations
})
const VideoPerformance = loadable(() =>
  import('../pages/insight/VideoPerformance')
)
const Invitation = loadable(() => import('../pages/Invitation'))
const AssistantMode = loadable(
  () => import('../pages/livestream/AssistantMode/AssistantMode'),
  {
    resolveComponent: (component) => component.AssistantMode
  }
)
const TabletAssistantMode = loadable(
  () => import('../pages/livestream/TabletAssistantMode/TabletAssistantMode'),
  {
    resolveComponent: (component) => component.TabletAssistantMode
  }
)
const CreateLiveStream = loadable(() =>
  import('../pages/livestream/CreateLiveStream')
)
const ViewLiveStream = loadable(() =>
  import('../pages/livestream/ViewLiveStream')
)
const ViewShowroomEvent = loadable(() =>
  import('../pages/showrooms/ViewShowroomEvent')
)

const ChannelVideoUpload = loadable(
  () => import('../pages/channel/ChannelVideoUpload'),
  {
    resolveComponent: (component) => component.ChannelVideoUpload
  }
)

const Addons = loadable(() => import('../pages/pricing/Addons'), {
  resolveComponent: (component) => component.Addons
})

const SubscriptionCallbackLanding = loadable(
  () => import('../pages/business/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionCallbackLanding
  }
)
const SubscriptionCancelLanding = loadable(
  () => import('../pages/business/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionCancelLanding
  }
)
const SubscriptionSettingsLanding = loadable(
  () => import('../pages/business/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionSettingsLanding
  }
)

const Embed = loadable(() => import('../pages/embed'), {
  resolveComponent: (component) => component.EmbedPage
})

const WPRedirect = loadable(() => import('../pages/home/WPRedirect'))
const Landing = loadable(() => import('../pages/Landing'), {
  resolveComponent: (component) => component.Landing
})
const NotFound = loadable(() => import('../pages/NotFound'))

const Notifications = loadable(() =>
  import('../pages/notifications/Notifications')
)
const NotificationDetail = loadable(() =>
  import('../pages/notifications/NotificationDetail')
)

const Payment = loadable(() => import('../pages/payment/Payment'))
const Showcase = loadable(() => import('../pages/Showcase'), {
  resolveComponent: (component) => component.Showcase
})

const ConsentRequest = loadable(() => import('../pages/ConsentRequest'))

const GeneralSearch = loadable(
  () => import('../pages/general-search/GeneralSearch'),
  {
    resolveComponent: (component) => component.GeneralSearch
  }
)

const IgAuth = loadable(() => import('../pages/instagram/IgAuth'))
const FbAuth = loadable(() => import('../pages/facebook/FbAuth'))
const TkAuth = loadable(() => import('../pages/tiktok/TkAuth'))
const GoogleAuth = loadable(() => import('../pages/google/GoogleAuth'))

const Paywall = loadable(() => import('../pages/paywall'), {
  resolveComponent: (component) => component.Paywall
})

const BulkUploadContainer = loadable(
  () => import('../components/BulkUpload/BulkUploadContainer'),
  {
    resolveComponent: (component) => component.BulkUploadContainer
  }
)

const SourceImporterContainer = loadable(
  () => import('../components/SourceImporter/SourceImporterContainer'),
  {
    resolveComponent: (component) => component.SourceImporterContainer
  }
)

const Guest = loadable(() => import('../pages/home/Guest'))

export default function createRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          element={
            <ErrorBoundary FallbackComponent={FallbackComponent}>
              <Root />
            </ErrorBoundary>
          }
        >
          <Route path="/overview" element={<Navigate to="/" />} />
          <Route
            path="/business/:businessId/overview"
            element={<Navigate to="/" />}
          />
          <Route path="/" element={<Landing />} />
          <Route path="/businesses" element={<BusinessList />} />
          <Route path="/business/create" element={<OnboardBusiness />} />
          <Route
            path="/business/:businessId/update"
            element={<BusinessForm mode={ONBOARDING_MODES.UPDATE} />}
          />
          <Route path="/businesses/associate" element={<BusinessAssociate />} />
          <Route
            path="/business_store_connect"
            element={<BusinessStoreConnect />}
          />
          <Route
            path="/business/:businessId/business_store_list"
            element={<BusinessStoreList />}
          />
          {OneToOneRoutes}
          {AvaRoutes}
          <Route
            path="/business/:businessId/channels"
            element={<ChannelList />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video_performance/:videoId"
            element={<VideoPerformance />}
          />
          <Route
            path="/business/:businessId/channel/:channelId"
            element={<Channel />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/insights/:subtab"
            element={<Channel />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/:tab"
            element={<Channel />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/bulk-upload"
            element={<BulkUploadContainer />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/source-importer"
            element={<SourceImporterContainer />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/mycontent"
            element={<MyContentCentral />}
          />
          {ChannelRoutes}
          {StoreRoutes}
          <Route
            path="/business/:businessId/channel/:channelId/livestream/create"
            element={<CreateLiveStream />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/livestream/create"
            element={
              <CreateLiveStream method={LIVESTREAM_CREATE_METHOD.FROM_VIDEO} />
            }
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId"
            element={<ViewLiveStream />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/showroom/:livestreamId"
            element={<ViewShowroomEvent />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/edit"
            element={<CreateLiveStream />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/assistant"
            element={<AssistantMode />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/host_dashboard"
            element={<TabletAssistantMode />}
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/create"
            element={
              <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.LOCAL_UPLOAD} />
            }
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId"
            element={
              <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />
            }
          />
          <Route
            path="/business/:businessId/channel/:channelId/video/:videoId/edit"
            element={
              <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />
            }
          />
          <Route
            path="/business/:businessId/channel/create"
            element={<CreateChannel />}
          />
          {AppRoutes}
          <Route
            path="/business/:businessId/subscription/callback"
            element={<SubscriptionCallbackLanding />}
          />
          <Route
            path="/business/:businessId/subscription/canceled"
            element={<SubscriptionCancelLanding />}
          />
          <Route
            path="/business/:businessId/subscription/settings"
            element={<SubscriptionSettingsLanding />}
          />
          <Route path="/packages" element={<Paywall />} />
          <Route
            path="/business/:businessId/subscription/packages/:packageId/addons"
            element={<Addons />}
          />
          <Route
            path="/business/:businessId/video_showcase"
            element={<Showcase />}
          />
          {BusinessAppRoutes}
          <Route path="/business/:businessId/payment" element={<Payment />} />
          {PricingPlanRoutes}
          {SettingRoutes}
          {ShowroomRoutes}
          <Route
            path="/business/:businessId/search"
            element={<GeneralSearch />}
          />
          {ProfileRoutes}
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/:id" element={<NotificationDetail />} />
          <Route path="/invitation/:token" element={<Invitation />} />
          <Route path="/wp_callback" element={<WPRedirect />} />
          <Route path="/advertiser" element={<Navigate to="/" />} />
          <Route path="/creator" element={<Navigate to="/" />} />
          <Route path="/publisher" element={<Navigate to="/" />} />
          <Route path="/pricing" element={<Navigate to="/" />} />
          <Route path="/integration" element={<Integrations />} />
          <Route path="/layout_studio" element={<Navigate to="/" />} />
          <Route
            path="/app/business/:businessId/channel/:channelId/video/:videoId/insights"
            element={<SigmaVideoInsights />}
          />
          <Route
            path="/app/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/insights"
            element={<SigmaLivestreamInsights />}
          />
          {SupportRoutes}
          {WebhookRoutes}
          <Route path="/instagram/auth" element={<IgAuth />} />
          <Route path="/facebook/auth" element={<FbAuth />} />
          <Route path="/tiktok/oauth" element={<TkAuth />} />
          <Route path="/google/oauth" element={<GoogleAuth />} />
          {AutomationRoutes}
          <Route path="/embed" element={<Embed />} />
          <Route path="/guest" element={<Guest />} />
          <Route path="/consent_request" element={<ConsentRequest />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </>
    )
  )
}
