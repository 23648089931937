import { useAppSelector } from '@src/app/hooks'
import { AppDispatch } from '@src/app/store'
import { validateResponseSuccess } from '@src/common/utils/api'
import {
  CTA_TYPE,
  InteractionType,
  MAX_CAPTION_LENGTH,
  MIN_CAPTION_LENGTH
} from '@src/constants'
import { usePixelAmpTracking } from '@src/hooks/index'
import useActivationStatus from '@src/hooks/Onboarding/useActivationStatus'
import { useCustomNavigate } from '@src/hooks/useCustomNavigate'
import { useToast } from '@src/hooks/useToast'
import { deleteFlowInteraction } from '@src/modules/channel/redux/Library/Journey/flowsApi'
import {
  createChannelVideo,
  createVideoInteraction,
  deleteVideoInteraction,
  fetchChannelAllVideos,
  fetchChannelCreatedVideos,
  fetchChannelVideoCount,
  updateChannelVideo,
  updateVideoInteraction
} from '@src/redux/channel'
import { createSubtitles, deleteSubtitle } from '@src/redux/video'
import { captionLength } from '@src/utils/caption'
import { convertOptionArray } from '@src/utils/products'
import { removeUndefined } from '@src/utils/sanitize'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { updateUrlProtocol } from '@src/utils/url'
import { composePosters } from '@src/utils/videoPoster'
import { round } from 'lodash'
import moment from 'moment'
import { UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import * as Yup from 'yup'

import { VUInputs, VUState } from './VU.types'
import { useVUContext } from './VUContext'

type Actions = {
  updateVideo: (
    values: any,
    reset: () => void,
    setError?: UseFormSetError<VUInputs>
  ) => Promise<void>
  createVideo: (values: any, reset: () => void, ref: any) => Promise<void>
  onChangeDate: (_date: any, dateString: any) => void
  onChangeTime: (_time: any, timeString: any) => void
  onClearSchedule: () => void
  onTimeSelect: (time: any) => void
  onDisabledMinutes: (selectedHour: any) => number[]
  onDisabledHours: () => any[]
  onDisabledDate: (current: any) => boolean
  validateInput: (values: any | undefined, video: any, state: VUState) => void
}

export const useVUPlayerActions = (): Actions => {
  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()
  const { navigateToHome } = useCustomNavigate()
  const { state, actions } = useVUContext()
  const dispatch: AppDispatch = useDispatch()
  const { businessId, channelId, videoId } = useParams()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const video: globalLib.IVideo = useAppSelector(
    (state) => state.content.videos[videoId]
  )

  const { setUploadVideoComplete } = useActivationStatus()

  const validateInput = (values, video, formState: VUState) => {
    const initCaption = video?.caption || ''
    const initDescription = video?.description
    const initHashtags = video?.hashtags || undefined
    const initPostTo = video?.access || 'public'
    const initAccessCode = video?.access_code || null
    const { caption, postTo, description, selectPlaylists, badge } =
      values ?? {}
    const initPlaylists = video?.playlists.map((p) => p.id) || []
    const hashtags = formState.hashtagArray.join(',')
    const initHashtagsString = initHashtags ? initHashtags.join(',') : ''

    const valuesChanged = values
      ? !!(
          initCaption !== caption ||
          initDescription !== description ||
          initPostTo !== postTo ||
          initPlaylists?.length !== selectPlaylists?.length ||
          !initPlaylists.every((item) => selectPlaylists.includes(item)) ||
          video.badge !== badge
        )
      : false

    const formStateChanged = formState
      ? !!(
          initAccessCode !== formState.accessCode ||
          initHashtagsString !== hashtags ||
          formState.mediaKey
        )
      : false

    const hasChanged = valuesChanged || formStateChanged
    if (formState.changed !== hasChanged) actions.setChanged(hasChanged)
  }

  const resetAll = () => {
    actions.setVideoUrl(null)
    actions.setVideoWH(null)
    actions.setMediaKey(null)
  }

  const showSuccessNotification = () => {
    successToast(t('Video Updated! Your video is being processed.'))
  }

  const updateVideo = async (
    values,
    reset,
    setError?: UseFormSetError<VUInputs>
  ): Promise<void> => {
    const { caption, postTo: access, badge } = values
    let publishAt = undefined
    // Video is going from public to private and has previous schedule
    // OR
    // Clearing existing schedule
    if (
      ((access === 'private' || access === 'unlisted') &&
        video?.access === 'public' &&
        moment(video.published_at).format() > moment().format()) ||
      state.clearSchedule
    ) {
      publishAt = moment().add(1, 'seconds').format()
    } else if (state.publishTime) {
      // Editing existing time
      publishAt = moment(state.publishTime).format()
    }

    const data: { [k: string]: unknown } = {
      caption,
      hashtags: state.hashtagArray,
      access,
      repostable: access !== 'private',
      published_at: publishAt,
      badge,
      action_url: null,
      action_type: null,
      action_custom_label: null,
      access_code: state.accessCode,
      playlist_ids: undefined
    }
    if (state.mediaKey) {
      data.original_key = state.mediaKey
    }

    if (
      video.playlists?.length !== state.selectPlaylists?.length ||
      (video.playlists?.length &&
        !video.playlists?.every((item) =>
          state.selectPlaylists.includes(`${item.id}`)
        ))
    ) {
      data.playlist_ids = state.selectPlaylists
    }

    data.products = state.selectedProductOptions
      ? convertOptionArray(state.selectedProductOptions)
      : state.selectedProductIds

    if (state.changedPosters) {
      data.video_posters = composePosters(
        state.newAnimatedPosterPayload,
        state.customGifAnimatedPosterPayload,
        state.newPosterPayload,
        state.newWidePosterPayload
      )
    }

    if (state.archived_at !== video?.archived_at) {
      data.archived_at = state.archived_at
    }

    /**
     * If products are NOT selected AND poll is NOT selected AND question is NOT selected
     * Set action_url and action_type
     */
    if (
      !state.selectedProductIds.length &&
      !state.pollObj &&
      !state.questionObj
    ) {
      const actionUrl = updateUrlProtocol(state.ctaLink)
      if (state.ctaType && !actionUrl && setError) {
        setError('action_url', { message: t('Please enter a valid link') })

        return
      }
      if (
        state.ctaType === CTA_TYPE.CUSTOM &&
        !state.ctaCustomLabel.length &&
        setError
      ) {
        setError('action_custom_label', {
          message: t('Please enter a custom label')
        })

        return
      }
      data.action_url = actionUrl
      data.action_type = state.ctaType
      data.action_custom_label = state.ctaCustomLabel ?? null
    }

    //Delete flow interaction if removed from video
    const allInteractionsRemoved =
      !state.selectedProductIds.length &&
      !state.pollObj &&
      !state.questionObj &&
      data.action_url === null &&
      data.action_type === null
    const multipleChoiceVideoInteraction =
      video?.flow_interaction?.type === InteractionType.MULTIPLE_CHOICE
    if (
      video.flow_interaction &&
      !multipleChoiceVideoInteraction &&
      allInteractionsRemoved
    ) {
      await dispatch(
        deleteFlowInteraction({
          businessId,
          channelId,
          flowId: video?.flow_interaction?.flow_id,
          interactionId: video?.flow_interaction?.id
        })
      )
    }

    const response = await dispatch(
      updateChannelVideo(businessId, channelId, video, data)
    )
    if (validateResponseSuccess(response)) {
      const hashtagArrayLength = state.hashtagArray?.length ?? 0
      const videoHashtagsLength = video.hashtags?.length ?? 0
      const playlistLength = video.playlists?.length ?? 0
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_UPDATE_POST, {
        visibility:
          video.access === access
            ? undefined
            : {
                original: video.access,
                final: access
              },
        hashtagsCount:
          hashtagArrayLength === videoHashtagsLength
            ? undefined
            : hashtagArrayLength,
        playlistCount:
          state.selectPlaylists?.length === playlistLength
            ? undefined
            : state.selectPlaylists?.length
      })
      const handleInteraction = async (interactionData) => {
        let result
        if (video.interactions && video.interactions.length) {
          result = await dispatch(
            updateVideoInteraction(
              video.encoded_id,
              video.interactions[0].id,
              interactionData,
              channelId,
              true
            )
          )
        } else {
          result = await dispatch(
            createVideoInteraction(
              video.encoded_id,
              interactionData,
              channelId,
              true
            )
          )
        }

        if (
          !result ||
          !result.status ||
          result.status < 200 ||
          result.status >= 300
        ) {
          errorToast(
            t('There was an error updating or creating the video interaction.')
          )

          return false
        }

        return true
      }

      if (state.questionObj) {
        const interactionData = {
          prompt: state.questionObj.text,
          collect_email: state.questionObj.collect
            ? state.questionObj.collect
            : null,
          interaction_type: 'question'
        }
        if (!(await handleInteraction(interactionData))) return
      } else if (state.pollObj) {
        const interactionData = {
          prompt: state.pollObj.question,
          options: state.pollObj.options.map((option) => ({ text: option })),
          interaction_type: 'poll'
        }
        if (!(await handleInteraction(interactionData))) return
      } else {
        // Deleting existing interaction
        if (video.interactions && video.interactions.length) {
          await dispatch(
            deleteVideoInteraction(
              video.encoded_id,
              video.interactions[0].id,
              channelId
            )
          )
        }
      }

      // deleting existing subtitles not included in the new list
      const keepSubtitleIds = state.subtitles.map((s) => s.id)
      if (video.video_subtitles) {
        video.video_subtitles
          .filter((s) => keepSubtitleIds.indexOf(s.id) === -1)
          .forEach(
            async (s) =>
              await deleteSubtitle(
                businessId,
                channelId,
                video.encoded_id,
                s.id
              )
          )
      }

      if (state.subtitles.length) {
        const subtitleData = {
          video_subtitles: state.subtitles
            .filter((s) => !s.id)
            .map((s) => ({
              key: s.key,
              language: s.language,
              is_cc: s.is_cc
            }))
        }
        await createSubtitles(
          businessId,
          channelId,
          video.encoded_id,
          subtitleData
        )
      }

      await dispatch(fetchChannelAllVideos(businessId, channelId))
      await dispatch(fetchChannelCreatedVideos(businessId, channelId))
      await dispatch(fetchChannelVideoCount(businessId, channelId))

      showSuccessNotification()

      resetAll()
      reset()
      navigateToHome({ businessId, channelId })
    } else {
      errorToast(t('There was an error updating the video. '))
    }
  }

  const createVideo = async (values, reset, ref): Promise<void> => {
    const { caption, soundtrack, postTo, badge } = values
    const { videoHeight, videoWidth, duration } = ref.current
    const hashtag = state.hashtagArray.join(',')
    const data = removeUndefined({
      access: postTo,
      caption,
      duration: duration ? round(duration, 2) : undefined,
      hashtags:
        hashtag && hashtag.includes(',')
          ? hashtag.replace(/ /g, '').split(',')
          : hashtag && hashtag.length > 0
          ? [hashtag]
          : undefined,
      height: videoHeight ? videoHeight : undefined,
      key: state.mediaKey,
      soundtrack_id: soundtrack,
      width: videoWidth ? videoWidth : undefined,
      published_at:
        state.access === 'private'
          ? undefined
          : state.publishTime
          ? state.publishTime.format()
          : undefined,
      repostable: postTo !== 'private',
      playlist_ids: state.selectPlaylists,
      badge: badge,
      action_url: null,
      action_type: null,
      access_code: state.accessCode
    })

    if (state.changedPosters) {
      data.video_posters = composePosters(
        state.newAnimatedPosterPayload,
        state.customGifAnimatedPosterPayload,
        state.newPosterPayload,
        state.newWidePosterPayload
      )
    }

    if (state.selectedProductIds.length || state.selectedProductOptions) {
      data.products = state.selectedProductOptions
        ? convertOptionArray(state.selectedProductOptions)
        : state.selectedProductIds.map((p) => {
            return { product_id: p }
          })
    }

    /**
     * If products are NOT selected AND poll is NOT selected AND question is NOT selected
     * Set action_url and action_type
     */
    if (
      !state.selectedProductIds.length &&
      !state.pollObj &&
      !state.questionObj
    ) {
      data.action_url = state.ctaLink ?? null
      data.action_type = state.ctaLink ? state.ctaType : null
      data.action_custom_label =
        state.ctaType === CTA_TYPE.CUSTOM ? state.ctaCustomLabel : null
    }

    actions.setConfirmLoading(true)
    const response = await dispatch(
      createChannelVideo(businessId, channelId, {
        ...data,
        creation_details: {
          source: 'cms',
          detail: 'short_video',
          app: 'web',
          creator_name: null
        }
      })
    )
    if (validateResponseSuccess(response)) {
      // Update activation status
      setUploadVideoComplete()

      if (state.questionObj) {
        const interactionData = {
          prompt: state.questionObj.text,
          collect_email: state.questionObj.collect
            ? state.questionObj.collect
            : null,
          interaction_type: 'question'
        }
        await dispatch(
          createVideoInteraction(
            response.data.encoded_id,
            interactionData,
            channelId
          )
        )
      }

      if (state.pollObj) {
        const interactionData = {
          prompt: state.pollObj.question,
          interaction_type: 'poll',
          options: state.pollObj.options.map((option) => ({
            text: option
          }))
        }
        await dispatch(
          createVideoInteraction(
            response.data.encoded_id,
            interactionData,
            channelId
          )
        )
      }

      if (state.subtitles.length) {
        const subtitleData = {
          video_subtitles: state.subtitles.map((s) => ({
            key: s.key,
            language: s.language,
            is_cc: s.is_cc
          }))
        }
        await createSubtitles(
          businessId,
          channelId,
          response.data.encoded_id,
          subtitleData
        )
      }

      resetAll()
      reset()

      // Send tracking
      const trackingProps = {
        _business_id: businessId,
        context: 'onboarding',
        _channel_id: channelId,
        stage: 'upload'
      }
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CREATE_POST, trackingProps)

      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_CREATE_CONTENT, {
        source: 'upload'
      })

      if (state.publishTime) {
        const trackingProps = {
          _business_id: businessId,
          _channel_id: channelId,
          _video_id: response.data.encoded_id,
          stage: 'upload'
        }
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_CLICK_SCHEDULE_UPLOAD,
          trackingProps
        )
      }

      showSuccessNotification()
      actions.setConfirmLoading(false)
      navigateToHome({ businessId, channelId })
    } else {
      actions.setConfirmLoading(false)
    }
  }

  const onChangeDate = (_date, dateString) => {
    if (!_date) return onClearSchedule()
    actions.setClearSchedule(false)
    actions.setShowUploadNow(false)
    let timeString
    if (state.publishTime) {
      timeString = moment(state.publishTime).format('hh:mm:ss a')
    } else {
      timeString = moment()
        .subtract(moment().minutes(), 'minutes')
        .add(1, 'hour')
        .format('hh:mm:ss a')
    }
    let data = `${dateString} ${timeString}`
    data = data.replace(/-/g, '/') //safari doesn't support yyyy-MM-dd https://stackoverflow.com/questions/4310953/invalid-date-in-safari
    actions.setPublishTime(moment(data))
  }

  const onChangeTime = (_time, timeString) => {
    actions.setClearSchedule(false)
    let dateString
    if (state.publishTime) {
      dateString = moment(state.publishTime).format('YYYY-MM-DD')
    } else {
      dateString = moment().format('YYYY-MM-DD')
    }
    let data = `${dateString} ${timeString}`
    data = data.replace(/-/g, '/') //safari doesn't support yyyy-MM-dd https://stackoverflow.com/questions/4310953/invalid-date-in-safari
    actions.setPublishTime(moment(data))
  }

  const onClearSchedule = () => {
    actions.setPublishTime(null)
    actions.setClearSchedule(true)
    actions.setShowUploadNow(true)
  }

  const onTimeSelect = (time) => {
    onChangeTime(time, time.format('h:mm a'))
    actions.setShowUploadNow(false)
  }

  const onDisabledMinutes = (selectedHour) =>
    disabledMinutes(selectedHour, state.publishTime)

  const onDisabledDate = (current: unknown) =>
    current &&
    (current.valueOf() <= state.originalPublishStartDate.firstDay ||
      current.valueOf() > state.originalPublishStartDate.lastDay)

  const onDisabledHours = () => {
    const hours = []
    if (state.publishTime?.isSame(new Date(), 'day')) {
      for (let i = 0; i < moment().hour(); i++) hours.push(i)
      if (moment().minute() > 30) hours.pop()
    }

    return hours
  }

  return {
    validateInput,
    createVideo,
    updateVideo,
    onChangeDate,
    onChangeTime,
    onClearSchedule,
    onTimeSelect,
    onDisabledMinutes,
    onDisabledDate,
    onDisabledHours
  }
}

const disabledMinutes = (selectedHour: number, publishTime: any): number[] => {
  const minutes = []
  if (publishTime?.isSame(new Date(), 'day')) {
    if (selectedHour < moment().hour()) {
      for (let i = 0; i < 60; i++) {
        minutes.push(i)
      }
    } else if (selectedHour === moment().hour()) {
      if (moment().minute() < 30) {
        for (let i = 0; i < moment().minute(); i++) {
          minutes.push(i)
        }
      } else {
        for (let i = 0; i < 60; i++) {
          minutes.push(i)
        }
      }
    } else if (selectedHour === moment().hour() + 1) {
      for (let i = 0; i < 30 - (60 - moment().minute()); i++) {
        minutes.push(i)
      }
    }
  }

  return minutes
}

export const validationSchema = (t: (s: string) => string): any => {
  return Yup.object().shape({
    caption: Yup.string()
      .trim()
      .required(t('Required'))
      .test('is-valid-length-min', t('Too short'), (value) => {
        return captionLength(value) >= MIN_CAPTION_LENGTH
      })
      .test('is-valid-length-max', t('Too long'), (value) => {
        return captionLength(value) <= MAX_CAPTION_LENGTH
      })
  })
}
