import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

import { SLICE_NAME } from './constants'
import { SliceState } from './index'
import { PDPGallery } from './types'

export const pdpGallery_enableFeature = createAsyncThunk(
  `${SLICE_NAME}/enableFeature`,
  async (params: {
    storeUrl: string
    position: number
    businessId: string
  }) => {
    const { data } = await api.post(`/bus/${params.businessId}/pdp_embed`, {
      position: params.position,
      status: 'enable',
      myshopify_domain: params.storeUrl
    })

    return data
  }
)

export const pdpGallery_pollStatus = createAsyncThunk<
  PDPGallery,
  { storeUrl: string; businessId: string }
>(
  `${SLICE_NAME}/pollStatus`,
  async (params: { storeUrl: string; businessId: string }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/pdp_embed?myshopify_domain=${params.storeUrl}`
    )

    return data as PDPGallery
  }
)

export const pdpGallery_disableFeature = createAsyncThunk(
  `${SLICE_NAME}/disableFeature`,
  async (params: {
    storeUrl: string
    businessId: string
    position: number
  }) => {
    const { data } = await api.post(`/bus/${params.businessId}/pdp_embed`, {
      status: 'disable',
      myshopify_domain: params.storeUrl,
      position: params.position
    })

    return data
  }
)

export const pdpGallery_updateFeature = createAsyncThunk(
  `${SLICE_NAME}/updateFeature`,
  async (params: {
    businessId: string
    position: number
    pdpConfigId: string
  }) => {
    const { data } = await api.patch(
      `/bus/${params.businessId}/pdp_embed/${params.pdpConfigId}`,
      {
        position: params.position,
        status: 'enable'
      }
    )

    return data
  }
)

export const pdpGallery_updateFeaturedProduct = createAsyncThunk(
  `${SLICE_NAME}/updateFeaturedProduct`,
  async (params: {
    businessId: string
    pdpComponentId: string
    productId: string
    videoId: string
  }) => {
    const { data } = await api.patch(
      `/bus/${params.businessId}/pdp_embed_components/${params.pdpComponentId}`,
      {
        product_id: params.productId,
        video_id: params.videoId
      }
    )

    return data
  }
)

export const pdpGalleryBuilder = (
  builder: ActionReducerMapBuilder<SliceState>
): void => {
  builder
    .addCase(pdpGallery_enableFeature.pending, (state) => {
      state.pdpGalleryConfig = {
        ...state.pdpGalleryConfig,
        status: 'processing'
      }
    })
    .addCase(pdpGallery_pollStatus.pending, (state) => {
      state.pdpGalleryConfig = {
        ...state.pdpGalleryConfig,
        status: 'processing'
      }
    })
    .addCase(pdpGallery_pollStatus.fulfilled, (state, action) => {
      state.pdpGalleryConfig = action.payload
    })
    .addCase(pdpGallery_pollStatus.rejected, (state) => {
      state.pdpGalleryConfig = {
        ...state.pdpGalleryConfig,
        status: null
      }
    })
    .addCase(pdpGallery_disableFeature.pending, (state) => {
      state.pdpGalleryConfig = {
        ...state.pdpGalleryConfig,
        status: 'processing'
      }
    })
    .addCase(pdpGallery_updateFeature.pending, (state) => {
      state.pdpGalleryConfig = {
        ...state.pdpGalleryConfig,
        status: 'processing'
      }
    })
    .addCase(pdpGallery_updateFeature.fulfilled, (state) => {
      state.pdpGalleryConfig = {
        ...state.pdpGalleryConfig,
        status: 'processing'
      }
    })
}
