import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router'

const Automation = loadable(() => import('../layout/Automation'))
const AutomationLogs = loadable(
  () => import('../pages/Automation/AutomationLogs')
)
const CreateAutomation = loadable(
  () => import('../pages/Automation/CreateAutomation')
)
const ListAutomations = loadable(
  () => import('../pages/Automation/ListAutomations')
)
const ViewAutomation = loadable(
  () => import('../pages/Automation/ViewAutomation')
)

export const AutomationRoutes = (
  <Route
    path="/business/:businessId/channel/:channelId/automation"
    element={<Automation />}
  >
    <Route path="" element={<ListAutomations />} />
    <Route path="logs" element={<AutomationLogs />} />
    <Route path="create" element={<CreateAutomation />} />
    <Route path=":automationId" element={<ViewAutomation />} />
  </Route>
)
