import React, { useEffect, useState } from 'react'

import { css } from '@emotion/react'
import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { FWButton, FWModal } from '@src/components'
import { Box } from '@src/components/EmotionLayout'
import Title from '@src/components/Title'
import { AUTO_EMBED_TABS } from '@src/constants'
import {
  isModalShownFromStorage,
  setModalShownInStorage,
  useGlobalPopupModals
} from '@src/hooks/useGlobalPopupModals'
import { useStoreFeatureEnablement } from '@src/hooks/useStoreFeatureEnablement'
import {
  BodyContainer,
  BodyText,
  FooterContainer,
  HeaderContainer,
  TitleContainer
} from '@src/pages/channel/Tabs/Integrations/ShopApp/NewShopModal.styles'
import { setIntegrationsActiveTab } from '@src/redux/autoEmbed'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

const NewShopModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { businessId, channelId } = useParams()
  const storeFeatureEnablement = useStoreFeatureEnablement(businessId)

  const [newShopModalVisible, setNewShopModalVisible] = useState(false)
  const { shouldShowModalGlobal } = useGlobalPopupModals('shop_app')

  useEffect(() => {
    const shopAppModal = isModalShownFromStorage('shop_app')
    if (!shopAppModal && storeFeatureEnablement?.shopify) {
      setNewShopModalVisible(true)
    }
  }, [storeFeatureEnablement])

  const handleCancelClick = () => {
    setNewShopModalVisible(false)
  }

  const videos = useAppSelector(
    (state) => state.channel.channelVideoCount[channelId]
  )

  const allVideosCount = videos?.allVideosCount

  return (
    <FWModal
      width={588}
      mask
      open={newShopModalVisible && shouldShowModalGlobal && allVideosCount > 0}
      onCancel={handleCancelClick}
      closable={false}
      title={null}
      footer={null}
      css={css`
        .ant-modal-content {
          border-radius: 12px;
        }
        .ant-modal-body {
          border-radius: 12px;
        }
      `}
    >
      <BodyContainer style={{ paddingRight: '36px', paddingLeft: '36px' }}>
        <HeaderContainer>
          <Box width={64} height={28} as="img" src="/images/shop.svg" />
        </HeaderContainer>
        <TitleContainer>
          <Title fontSize={20} weight={600} noMargin>
            {t('New Shop App Integration')}
          </Title>
          <BodyText>
            {t(
              'You can now publish your Firework video content to your store’s Brand and Product Pages on the Shop App.'
            )}
          </BodyText>
        </TitleContainer>
        <Box
          width={250}
          height={150}
          as="img"
          src="/images/shop_brand_page_2.svg"
        />
        <FooterContainer>
          <FWButton
            type="default"
            onClick={() => {
              setModalShownInStorage('shop_app')
              handleCancelClick()
            }}
            style={{ width: '150px' }}
          >
            {t(`Don't Show Again`)}
          </FWButton>
          <FWButton
            style={{ marginLeft: '12px', width: '150px' }}
            type="primary"
            onClick={() => {
              setModalShownInStorage('shop_app')
              dispatch(setIntegrationsActiveTab(AUTO_EMBED_TABS.SHOP_APP))
              navigate(
                `/business/${businessId}/channel/${channelId}/integrations`
              )
              handleCancelClick()
            }}
          >
            {t('Get Started')}
          </FWButton>
        </FooterContainer>
      </BodyContainer>
    </FWModal>
  )
}

export default NewShopModal
