import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  igClientId,
  IgToken,
  getRedirectUri,
  saveIgToken,
  IG_URL,
  getIgToken
} from '@src/utils/igAuth'
import { Dispatch } from 'redux'

import api, { IMPORTER_PROXY_URL } from '../common/utils/api'

const slice = createSlice({
  name: 'igAuth',
  initialState: {},
  reducers: {
    fetchIgTokenSuccess(state, action) {
      const { userId, igToken } = action.payload
      saveIgToken(userId, igToken)
    },
    refreshIgTokenSuccess(state, action) {
      const { userId, igToken } = action.payload
      saveIgToken(userId, igToken)
    }
  }
})

export default slice.reducer
export const { fetchIgTokenSuccess, refreshIgTokenSuccess } = slice.actions

const fetchIgTokenRequest = createAction('igAuth/fetchIgTokenRequest')
const fetchIgTokenFailure = createAction('igAuth/fetchIgTokenFailure')

export function code2Token(userId: string, code: string) {
  return async (dispatch: Dispatch): Promise<IgToken> => {
    try {
      dispatch(fetchIgTokenRequest())
      const response = await fetch(`${IMPORTER_PROXY_URL}/instagram/exchange`, {
        method: 'POST',
        body: JSON.stringify({
          client_id: igClientId,
          redirect_uri: getRedirectUri(),
          code
        }),
        headers: {
          Authorization: `bearer ${api.getToken()}`,
          'Content-Type': 'application/json'
        }
      })
      const data = (await response.json()) as any
      const { access_token, expire_time } = data

      const meRes = await fetch(
        `${IG_URL}/me?fields=id,username&access_token=${access_token}`
      )
      const { id, username } = (await meRes.json()) as any

      const expireDate = new Date(expire_time)
      const igToken: IgToken = {
        accessToken: access_token,
        expiresIn: expireDate.getTime(),
        id,
        username
      }

      dispatch(
        fetchIgTokenSuccess({
          userId,
          igToken
        })
      )

      return igToken
    } catch (error) {
      dispatch(fetchIgTokenFailure())

      return error
    }
  }
}

const refreshIgTokenRequest = createAction('igAuth/refreshTokenRequest')
const refreshIgTokenFailure = createAction('igAuth/refreshTokenFailure')

export function refreshToken(userId: string) {
  return async (dispatch: Dispatch): Promise<IgToken> => {
    try {
      dispatch(refreshIgTokenRequest())
      const curIgToken = getIgToken(userId)
      const response = await fetch(
        `${IG_URL}/refresh_access_token?grant_type=ig_refresh_token&access_token=${curIgToken.accessToken}`
      )
      const data = (await response.json()) as any
      const { access_token, expires_in } = data

      const date = new Date()
      const igToken = {
        accessToken: access_token,
        expiresIn: date.getTime() + expires_in * 1000,
        id: curIgToken.id,
        username: curIgToken.username
      }

      dispatch(
        refreshIgTokenSuccess({
          userId,
          igToken
        })
      )

      return data
    } catch (error) {
      dispatch(refreshIgTokenFailure())

      return error
    }
  }
}
