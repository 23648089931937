import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router'

const CreateCohort = loadable(
  () => import('../pages/DynamicContent/CreateCohort')
)
const EditCohort = loadable(() => import('../pages/DynamicContent/EditCohort'))
const ViewCohort = loadable(() => import('../pages/DynamicContent/ViewCohort'))

export const DynamicContentRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId/create-cohort"
      element={<CreateCohort />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/cohort/:cohortId"
      element={<ViewCohort />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/cohort/:cohortId/edit"
      element={<EditCohort />}
    />
  </>
)
